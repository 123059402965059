import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree,useLoader } from "@react-three/fiber";
import { a } from '@react-spring/three'
import * as THREE from 'three'
import ModelScene from '../accests/3d/officce_n.glb'
import gsap from "gsap";
import img from '../accests/logo.png'
 

const Officce_n = (props) => {
  
  const { nodes, materials } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false)
  materials.плакат.metalness=.7
  const colorChange = async () => {
    let key = Object.keys(materials)
    if (props.fog && !hovered)
      key.forEach(element => {
        if (element != 'окна' && element != 'выступы')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5
          });
      });
    else {
      key.forEach(element => {
        if (element != 'окна' && element != 'выступы')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0
          });
      });
    }
  }
  useEffect(() => {
    colorChange()
  }, [props.fog])
  const modelRef = useRef()
  return (
    <a.group {...props} ref={modelRef}
      onPointerOver={(event) => {
        event.stopPropagation()
        hover(true)
        props.setFog(true)
      }
      }
      onPointerOut={(event) => {
        hover(false)
        props.setFog(false)
      }}
      onClick={()=>{props.select(8)}}
    ><group position={[-32.884, 0, -25.21]} scale={1.741}>
        <mesh
          castShadow
          // receiveShadow
          geometry={nodes.Circle039.geometry}
          material={materials.плакат}
          position={[18.891, 0, 14.483]}
          scale={[0.245, 0.245, 0.248]}
        >
        </mesh>
        <group position={[18.891, 0, 14.483]} scale={2.116}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube004.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube004_1.geometry}
            material={materials.дверь}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube004_2.geometry}
            material={materials["Material.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube004_3.geometry}
            material={materials["Material.005"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube004_4.geometry}
            material={materials["ступеньки.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube004_5.geometry}
            material={materials["Material.006"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.боковые_заглушки.geometry}
          material={materials["Material.004"]}
          position={[18.891, 0, 14.483]}
          scale={0.068}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.окно.geometry}
          material={materials.стекло}
          position={[18.891, 0, 14.483]}
          scale={2.116}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.рамы.geometry}
          material={materials["Material.004"]}
          position={[18.891, 0, 14.483]}
          scale={[2.116, 2.25, 0.06]}
        />
      </group>
    </a.group>
  );
}

export default Officce_n;