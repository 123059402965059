import React, { Suspense, useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import Loader from '../components/Loader';
import House from '../models/House';
import axios from "axios";
import { Bird } from '../models/Bird';
import DOMPurify from "dompurify";
import InnerHTML from "dangerously-set-html-content";
import { ReactComponent as Chevron } from "../accests/chevron.svg";
import { ContactShadows, Environment, OrbitControls, OrthographicCamera, PerformanceMonitor, Stage, useHelper } from '@react-three/drei';
import { AxesHelper } from 'three';
import { useControls } from "leva";
import Planep from '../models/Plane';
import Road from '../models/Road';
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper';
import Map from '../models/Map';
import Med from '../models/Med';
import Farm from '../models/Farm';
import RedSocks from '../models/RedSocks';
import { Boath } from '../models/Boath';
import COD from '../models/COD';
import { sRGBEncoding } from "three";
import CEX from '../models/CEX';
import PishProizvodstvo from '../models/PishProizvodstvo';
import Stadion from '../models/Stadion';
import Header from '../components/Header';
import LMenu from '../components/LMenu';
import Office from '../models/Office';
import Doroga_n from '../models/Doroga_n';
import Stadion_n from '../models/Stadion_n';
import Skad_n from '../models/Skad_n';
import Prom_n from '../models/Prom_n';
import Ozelenenie_n from '../models/Ozelenenie_n';
import Officce_n from '../models/Officce_n';
import Museum_n from '../models/Museum_n';
import Hospital_n from '../models/Hospital_n';
import Fabrika_n from '../models/Fabrika_n';
import Derevo_n from '../models/Derevo_n';
import COD_n from '../models/COD_n';
import Fura_anim_n from '../models/Fura_anim_n';
import Plane_n from '../models/Plane_n';
import RMenu from '../components/RMenu';
import Car_anim_n from '../models/Car_anim_n';
import RMenuOborudovanie from '../components/RMenuOborudovanie';
import pdfSVG from '../accests/pdf.svg'
import Car_all from '../models/Car_all';
import Ozelenenie2 from '../models/Ozelenenie2';
import Derevo_n2 from '../models/Derevo_n2';
import Fabrika_n2 from '../models/Fabrika_n2';
import Stadion_n2 from '../models/Stadion_n2';
import Prom_n2 from '../models/Prom_n2';
import Museum_n2 from '../models/Museum_n2';
import Ozelenenie_n2 from '../models/Ozelenenie_n2';
import COD_n2 from '../models/COD_n2';
import Fontan_n from '../models/Fontan_n';
import Svetofor_n from '../models/Svetofor_n';
import Fonari_n from '../models/Fonari_n';
import Klumbi_n from '../models/Klumbi_n';
import Dorogki_n from '../models/Dorogki_n';
import Allei_n from '../models/Allei_n';
import Skameiki_n from '../models/Skameiki_n';
import Ploshadka_n from '../models/Ploshadka_n';
import Derevo2_n from '../models/Derevo2_n';
import Doma_masovka_n from '../models/Doma_masovka_n';
import Cran from '../models/Cran';
// import e from '../accests/envv.hdr'
const useOrthographicCamera = true
const useContactShadow = false
const useShadow = true
const useRing = true
const night = false

export const Main = () => {
    const rectAreaLightRef = useRef();
    const reactDire = useRef();
    const [selectObject, setSelectObject] = useState(-1)
    const [itemOpen, setItemOpen] = useState(-1)
    const [leftMenuOpen, setLeftMenuOpen] = useState(false)
    const [rightMenuOpen, setRightMenuOpen] = useState(false)
    const [dataRMenu, setDataRMenu] = useState(null)
    const [dataIdLMenu, setDataIdLMenu] = useState(-1)
    const [aloneItem, setAloneItem] = useState(null)
    const [objectId, setObjectId] = useState(-1)
    const [fog, setFog] = useState(false);
    const [hovered, hover] = useState(false)
    const [selectGallery, setSelectGallery] = useState(false)
    const [selectGalleryData, setSelectGalleryData] = useState(null)
    const [category, setCategory] = useState(false)
    const [categoryData, setCategoryData] = useState(null)
    const [categoryDataRows, setCategoryDataRows] = useState(null)
    const handleLeftMenuOpen = () => {
        // //console.log("handleLeftMenuOpen")
        if (leftMenuOpen) {
            setLeftMenuOpen(false)
        } else {
            setItemOpen(-1)
            setSelectObject(-1)
            setSelectGallery(false)
            setSelectGalleryData(null)
            setDataRMenu(null)
            setLeftMenuOpen(true)
            setRightMenuOpen(false)
            setSelectObject(-1)
            setDataIdLMenu(-1)
            setAloneItem(null)
            setCategoryData(null)
            setCategory(false)
            setCategoryDataRows(null)
        }
    }
    const adjustHouseScreenSize = () => {
        let screenScale = null;
        // let screenPosition = [0, -5, -20];
        let screenPosition = [0, 0.07, 0];
        let rotation = [0.1, 10.7, 0];
        if (window.innerHeight < 768) {
            screenScale = [0.3, 0.3, 0.3];
        } else {
            screenScale = [.25, .25, .25];
        }
        return [screenScale, screenPosition, rotation];
    }
    const adjustPlaneScreenSize = () => {
        let screenScale = null;
        let screenPosition = [0, 0, 10];
        if (window.innerHeight < 768) {
            screenScale = [0.9, 0.9, 0.9];
        } else {
            screenScale = [1, 1, 1];
        }
        return [screenScale, screenPosition];
    }
    const adjustRoadScreenSize = () => {
        let screenScale = null;
        let screenPosition = [-2, -0.02, 0];
        let screenRotation = [0, -1, 0];
        if (window.innerHeight < 768) {
            screenScale = [.3, .3, .3];
        } else {
            screenScale = [.3, .3, .3];
        }
        return [screenScale, screenPosition, screenRotation];
    }
    const [houseSacel, housePosition, houseRotation] = adjustHouseScreenSize();
    const [planeScale, planePosition] = adjustPlaneScreenSize();
    const [roadScale, roadPosition, roadRotation] = adjustRoadScreenSize();

    // const { intensity, width, height, color } = useControls({ intensity: { value: 1, min: 0, max: 20 }, width: { value: 3, min: 1, max: 10 }, height: { value: 3, min: 1, max: 10 }, color: '#fff' });
    // const { intensity2, width2, height2, color2 } = useControls({ intensity: { value: 1, min: 0, max: 20 }, width: { value: 3, min: 1, max: 10 }, height: { value: 3, min: 1, max: 10 }, color: '#fff' });


    function Rig() {
        const { camera, mouse } = useThree()
        const vec = new THREE.Vector3()
        return useFrame(() => {
            camera.position.lerp(vec.set(mouse.x * 2, camera.position.y, camera.position.z), 0.05)
        })
    }
    const getAbout = async () => {
        setLeftMenuOpen(false)
        const datat = await axios.get("/api/companys_posts/");
        if (datat.status === 200) {
            let bufRows = [];
            let bufHeaders = [];
            for (let row of datat.data) {
                bufRows.push(row);
            }
            setDataIdLMenu(2)
            setDataRMenu({ name: "О компании", data: bufRows });
            setLeftMenuOpen(false)
        }
        // props.setDataRMenu({ name: "О компании", data: bufRows });
        // const datat = await axios.get("/api/office");
        // if (datat.status === 200) {
        //     let bufRows = [];
        //     let bufHeaders = [];
        //     for (let row of datat.data) {
        //         bufRows.push(row);
        //     }
        //     setDataRMenu({ name: "О компании", data: bufRows });
        //     setLeftMenuOpen(false)
        // }
    };
    const getCategory = async () => {

        // const datat = await axios.post(`/api//solutions_posts/name?post_name=${name}`);
        const datat = await axios.get(`/api//solutions_posts/${selectObject}`);
        if (datat.status === 200) {
            let bufRows = [];
            let bufHeaders = [];
            let id = 0
            if (datat.data) {
                bufRows.push(datat.data)
            }
            // for (let row of datat.data) {
            //     // if(id === selectObject-1){
            //     //     row.open=true
            //     // }else{
            //     //     row.open=false
            //     // }
            //     // id=id+1
            //     bufRows.push(row);

            // } 
            // setDataRMenu({ name: "Решения", data: bufRows, open:selectObject });
            setDataIdLMenu(3)
            // setItemOpen(selectObject)
            setDataRMenu({ name: "Решения", data: bufRows });
            // setCategory(1)
            setLeftMenuOpen(false)
            setObjectId(1)

        } else {
            setObjectId(-1)
            setDataIdLMenu(-1)
            setCategory(false)
            setDataRMenu(null)
            setAloneItem(null)
            setCategoryData(null)
            setSelectGallery(false)
            setSelectGalleryData(null)
        }
    };
    useEffect(() => {
        // //console.log("selectObject", selectObject)
        // setDataRMenu(null)
        if (selectObject === 8) {
            setObjectId(-1)
            setDataIdLMenu(-1)
            setCategory(false)
            setAloneItem(null)
            setCategoryData(null)
            setSelectGallery(false)
            setSelectGalleryData(null)
            getAbout()

        } else if (selectObject != 8 && selectObject != -1) {
            getCategory()
            setCategory(false)
            setAloneItem(null)
            setDataIdLMenu(-1)
            setCategoryData(null)
            setSelectGallery(false)
            setSelectGalleryData(null)

        } else {
            setObjectId(-1)
            setCategory(false)
            setDataIdLMenu(-1)
            setAloneItem(null)
            setCategoryData(null)
            setSelectGallery(false)
            setSelectGalleryData(null)
            // setDataRMenu(null)
        }
    }, [selectObject])
    useEffect(() => {
        // //console.log("data")
    }, [dataRMenu])
    const getAllPost = async () => {
        const datat = await axios.get(`/api/references_posts/${selectGallery}`);
        setSelectGalleryData(datat.data);
        // //console.log("dataGallery", datat)
    };

    const getCategoryEquipment = async () => {
        const datat = await axios.get(`/api/category/tag${selectGallery}`);
        setSelectGalleryData(datat.data);
        // //console.log("dataGallery", datat)
    }
    const getDataEquipmentGroups = async (groupName) => {
        const datat = await axios.get(`/api/equipments_groups/${groupName}`);
        setDataRMenu(null)
        if (datat.data) {
            setDataRMenu({
                "data": datat.data
            }
            )
        }
    }

    const getDataEquipmentGroupsPost = async (groupId) => {
        const datat = await axios.get(`/api/equipments_posts/${groupId}`);
        if (datat.data) {
            setSelectGalleryData(
                datat.data
            )
        }

    }
    useEffect(() => {

        if (category) {
            getDataEquipmentGroups(categoryData.id)
            // if (categoryData.title == 'FISAIR') {
            //     //console.log("dataRMenu", dataRMenu)
            //     setDataRMenu({
            //         "data":
            //             [
            //                 {
            //                     "_id": "65137035aec7f74e191150fc",
            //                     "title": "Вентиляция и кондиционирование",
            //                     "text": "Вентиляция и кондиционирование"
            //                 },
            //                 {
            //                     "_id": "65137035aec7f74e191150fc",
            //                     "title": "Воздухораспределение и регулирование воздуха",
            //                     "text": "Воздухораспределение и регулирование воздухаВоздухораспределение и регулирование воздухаВоздухораспределение и регулирование воздухаВоздухораспределение и регулирование воздуха"
            //                 },
            //                 {
            //                     "_id": "65137035aec7f74e191150fc",
            //                     "title": "Промышленное увлажнение воздуха",
            //                     "text": "Промышленное увлажнение воздухаПромышленное увлажнение воздухаПромышленное увлажнение воздухаПромышленное увлажнение воздуха "
            //                 },
            //                 {
            //                     "_id": "65137035aec7f74e191150fc",
            //                     "title": "Промышленные осушители воздуха",
            //                     "text": "Промышленные осушители воздухаПромышленные осушители воздухаПромышленные осушители воздухаПромышленные осушители воздуха"
            //                 },
            //                 {
            //                     "_id": "65137035aec7f74e191150fc",
            //                     "title": "Оборудование для холодоснабжения",
            //                     "text": "Оборудование для холодоснабженияОборудование для холодоснабженияОборудование для холодоснабжения"
            //                 }
            //             ]
            //     }
            //     )
            // }
        }
        // //console.log("category", categoryData)

    }, [category])
    useEffect(() => {
        setSelectGalleryData(null);
        // //console.log("asdasdasd", selectGallery)
        if (selectGallery) {
            // //console.log("selectGallery", selectGallery)
            if (category) {
                getDataEquipmentGroupsPost(selectGallery.id)
                // if (selectGallery.title == 'Вентиляция и кондиционирование') {
                //     setSelectGalleryData(
                //         [
                //             {
                //                 "_id": "6549552bf2f6ea3a9907d44c",
                //                 "title": "Smartluft Центральный кондиционер",
                //                 "text": "Производитель: OC IMP KLIMA<br>Тип: Центральные кондиционеры",
                //                 "tags": [],
                //                 "more": "<bold>Основные характеристики объекта:<bold>\
                //                 Единая технологическая схема, основанная на поточности производства, его комплексной механизации и автоматизации\
                //                 Технологический процесс на табачных фабриках сопровождается выделением вредных веществ, а также избыточной теплоты\
                //                 Основная вредность табачного производства — табачная пыль, выделение которой происходит на всех его участках\
                //                 Задачи системы вентиляции и кондиционирования на табачной фабрике:\
                //                 Обеспечение заданных условий эксплуатации, технологического оборудования\
                //                 Повышение энергоэффективности инженерных систем\
                //                 В зависимости от назначения помещений требуются различные параметры поддержания микроклимата\
                //                 Для обеспечения стандартов безопасности, предъявляемых к табачным производствам\
                //                 Для обеспечения необходимого расхода наружного воздуха, и кратности воздухообмена в производственных и административных помещениях\
                //                 Поддержание качества готовой продукции на высоком уровне/уменьшение количества брака\
                //                 Защита окружающей среды от вредных выбросов производства",
                //                 "photoOpen": "https://s3-alpha-sig.figma.com/img/2d36/7767/6eabbff03da9e5d67842b73d98b9387e?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Mr0K0oy-AcT-RU2uBwU2O5EUwwUEZ3aUyhlGpl0~RG7lpoDs0N-4EZv6g7TlyjJ2QHvkrp0Cp3FB7zYrXTpWCuVS2OS7V5femilRR0fqe-5w05fKQAFfViDA7h3oaJbJEYSgQ-ec8NYydhmAmbOTGylzQIjw6ezzAMgvONGLacD0TB-LvXLnaaeNH4K~dvUJY2ae0~t4I-Jl3Ix~7oCTSR1PdB3h9rl6S08O3aPLA83eBuBDfsXn7NYxS64O1MeHmyPSdklBTwrxF7Q8JtOgY40ZNCCefaytGxNOcRj64dayppxUj8GRlizMKCgDHvltvtX5nzMeIaO6bsVM9Yx12Q__",
                //                 "categori": "653c2a5ace43a0e74c6b50db",
                //                 "photo": "https://s3-alpha-sig.figma.com/img/28e2/9cb0/d6b6a34c6f9b17913676f6596c4b81d4?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=H3k2zHrZqJasyTMqJNlcPzxiKiVyvCT8jMogoGO4m5IAJNd-neeJaqWNCwlFAV~ChRIOAwNMpg8VaW6BSeM49A5E9RVEfxFufdPe4bisNVQL~DMqIWUWRE6YuuL-AIB7xbE2G0ML75Gr9Njioz4cDXkNy7fZ2XlDPfam5-Cp~WeFN7uIhyS-V0XF9aa5FCuBSUI00KXyOxqB~sRT3on-7sYHzG-QVIW04wnRcRHPaNPtu-fWXutehp5tgRYgDu6P6ke5xcHQcdiGMFtDuS20Jed9~LttSDNuEOHxCWk-1u0XyhuM5CmeH1dyut-o8ewHrOrTC93Pc5vdG725kcAFQA__",
                //                 "viewsCount": 0,
                //                 "user": "65135de16e4a5fa68de0a341",
                //                 "createdAt": "2023-11-06T21:05:47.651Z",
                //                 "updatedAt": "2023-11-06T21:20:38.211Z",
                //                 "__v": 0
                //             },
                //             {
                //                 "_id": "654958eaf2f6ea3a9907d4d3",
                //                 "title": "TOPAIR Центральный кондиционер в гигиеническом исполнении",
                //                 "text": "Производитель: OC IMP KLIMA<br>Тип: Центральные кондиционеры",
                //                 "tags": [],
                //                 "categori": "653c2a5ace43a0e74c6b50db",
                //                 "photo": "https://s3-alpha-sig.figma.com/img/e8fe/90a0/183bfff828bd6565ace442a382fe319d?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=L83qyQesf-AtXw4l1oshwOW0n4JhpDZMfxkXzelz1V7T7wGhNwJjyVhTHkIR~f6zoBXgVjSTnVRZWdmPx8sARvduiPR4DCgqDE67RiQJfdxpjHfcwQNDQpDQJb5zsIu0RUredM6~EcMeN2MGYi8LjySec1Nc94Ez9fO3bEhyzBMivYyTs~RZdbLV1qFKDwRV6lI6uoYTRrdMN1Ci6TzI0gjAnQsjGwI3bR9Mh5VEIrkA-vMmF2Hc9f6Fji0JB9dM25yYub644CUO0kaM6ER7Iq3mPip2mSArnaeeMGBgA8YLs0COS~IF1vPV1QbP-PtsSA5szCGr9cIEw7LFKSwq3w__",
                //                 "viewsCount": 0,
                //                 "user": "65135de16e4a5fa68de0a341",
                //                 "createdAt": "2023-11-06T21:21:46.471Z",
                //                 "updatedAt": "2023-11-07T00:30:48.349Z",
                //                 "__v": 0
                //             },
                //             {
                //                 "_id": "654958eaf2f6ea3a9907d4d2",
                //                 "title": "COMPAIR Компактный моноблочный кондиционер ",
                //                 "text": "Производитель: OC IMP KLIMA<br>Тип: Центральные кондиционеры",
                //                 "tags": [],
                //                 "categori": "653c2a5ace43a0e74c6b50db",
                //                 "photo": "https://s3-alpha-sig.figma.com/img/8e88/7ae8/07537eaafa846ddde216c5c9a77d9709?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=H0AS5HYgKtchJs19abm2wpONADLrLQ1W3GcgDKEuyUoPVB561LxcS~nqbrlr5Oly-OEejMl71eQfDKG7rrb9n5ejm10akUOXWnMu5PcwRb5UAesH-lUcACIKXYRBO3~rMrv-nve5EK~78jAsrofYXrX8qWharCV5VMtyfnK6JZd2XS3uYRUSH480L0chCSU3znWWS7LsRRjljE7GHwrbdBORXnlbVXIrR5jwlmsukAPdX1P1Hxo1CocuM1yv7OV~AG4EaJ5GnNyy2jtsJdyOg4jTQrrWJ-2z3GXCoBWdIRZNbeyl62Lpzez3BmAdT1vPZKeCORz9CZ1mE9v9c~W8vA__",
                //                 "viewsCount": 0,
                //                 "user": "65135de16e4a5fa68de0a341",
                //                 "createdAt": "2023-11-06T21:21:46.471Z",
                //                 "updatedAt": "2023-11-07T00:30:48.349Z",
                //                 "__v": 0
                //             }
                //         ]


                //     )
                // }
                // //console.log("category", category)
            } else {
                getAllPost();
            }
        } else {
            // //console.log("category1", category)
            setSelectGalleryData(null);
        }
    }, [selectGallery]);

    const getDocuments = async (element) => {
        const datat = await axios.get(`/api/documents/${element.id}`);
        let buf = element
        if (datat.data) {
            buf['documents'] = datat.data
        }
        setAloneItem(buf);
        // //console.log("buf", buf)
    };

    const handleAloneItem = (item) => {
        getDocuments(item)
        // setAloneItem(item)

    }

    useEffect(() => {
        // alert(selectMenu)
    }, [aloneItem])

    const [dpr, setDpr] = useState(1)
    return (
        <div>

            <div className="mainContainer">
                <Header />
                <LMenu setOpen={handleLeftMenuOpen} open={leftMenuOpen} setDataRMenu={setDataRMenu} setDataIdLMenu={setDataIdLMenu} />
                {!aloneItem && !leftMenuOpen && dataRMenu && !category && <RMenu data={dataRMenu} selectObject={itemOpen} setAloneItem={setAloneItem} setCategory={setCategory} setCategoryData={setCategoryData} open={objectId} selectMenu={dataIdLMenu} selectGallery={setSelectGallery} setSelectObject={setSelectObject} />
                }
                {!aloneItem && !leftMenuOpen && category && <RMenuOborudovanie data={dataRMenu} categoryData={categoryData} setAloneItem={setAloneItem} open={objectId} selectMenu={dataIdLMenu} selectGallery={setSelectGallery} setSelectObject={setSelectObject} />}
                {!aloneItem && selectGallery && selectGalleryData &&
                    <div className='gallery' style={{ right: '23vw', width: "60vw", overflow: 'auto', maxHeight: '80vh', display: 'grid', gridGap: '20px', justifyContent: 'center', position: 'fixed', top: '8em', zIndex: '100', boxShadow: 'none', }} >
                        {selectGalleryData.map((item) =>
                            <div className='galleryItem'>
                                <img className='galleryItemImg' src={item?.preview ?
                                    item.preview == '' ?
                                        'https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg' :
                                        item.preview :
                                    item.img == '' ?
                                        'https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg' :
                                        item.img}>

                                </img>
                                <div className='galleryItemContent' style={{
                                    position: "relative"
                                }}>
                                    <p className='galleryItemTitle'>
                                        {item.name}
                                    </p>
                                    <InnerHTML className='galleryItemText' html={item.text}></InnerHTML>
                                    <br></br>
                                    {item?.description && (
                                        <a
                                            style={{
                                                textDecoration: "underline",
                                                position: "absolute",
                                                cursor: "pointer",
                                                bottom: "10px"
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleAloneItem(item)

                                                // if (more == id) {
                                                //     setMore(-1);
                                                // } else setMore(id);
                                            }}
                                        >
                                            Подробнее
                                        </a>
                                    )}
                                </div>

                            </div>)}
                    </div>
                }
                {aloneItem &&
                    <div >

                        {aloneItem?.documents &&
                            <div className='aloneDocuments'>
                                {aloneItem?.documents?.map((item, id) =>
                                    <a target="_blank" className='documentItem' key={id} href={`${item.path}`} >
                                        <img src={pdfSVG} width="44px" style={{ "paddingRight": "10px" }}></img>
                                        <p>{item.name}</p>
                                    </a>
                                )}

                            </div>
                        }
                        {aloneItem?.main &&
                            <RMenuOborudovanie data={dataRMenu} categoryData={categoryData} setAloneItem={setAloneItem} open={objectId} selectMenu={dataIdLMenu} selectGallery={setSelectGallery} setSelectObject={setSelectObject} />}
                        <div className='aloneItem'>
                            <div className="buttonBack" onClick={() => {
                                setAloneItem(null)
                            }}>
                                <Chevron
                                    fill='white'
                                    stroke='white'
                                    style={{
                                        color: "#FFF"
                                    }}
                                    width={"25px"}
                                    height={"25px"}
                                // style={{
                                //     transform: navigationMenuVisibleState
                                //         ? "rotate(540deg)"
                                //         : "rotate(0)",
                                //     transition: "all 0.8s",
                                // }}
                                />
                            </div>
                            <div className="containerAloneItem">
                                <div className="image" style={{
                                    backgroundImage: `url("${aloneItem?.img ?
                                        aloneItem.img == '' ?
                                            'https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg' :
                                            aloneItem.img :
                                        aloneItem.preview == '' ?
                                            'https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg' :
                                            aloneItem.preview}")`,
                                    backgroundSize: "100%",
                                }}>
                                    {/* <img src={
                                    aloneItem?.img ?
                                        aloneItem.img == '' ?
                                            'https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg' :
                                            "https://smartluft.apa-systems.ru/"+aloneItem.img :
                                        aloneItem.preview == '' ?
                                            'https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg' :
                                            aloneItem.preview}></img> */}
                                </div>
                                <div className="title" style={{paddingBottom:'10px'}}>
                                    <p>{aloneItem.name}</p>
                                </div>
                                {/* <InnerHTML className='more' html={aloneItem.description}></InnerHTML> */}
                                {/* <div dangerouslySetInnerHTML={{ __html: aloneItem.description ?? '' }}></div> */}
                                <div className="aloneItemContent" style={{"padding": '5px 11px', paddingBottom: '20px', paddingRight: '20px'}}
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(aloneItem.description ?? ''),
                                    }}
                                ></div>
                            </div>

                        </div>
                    </div>
                }
                <section className='w-full h-screen relative'
                // style={{display: "none"}}
                >
                    <Canvas

                        style={{ background: night ? "gray" : "white" }}
                        className={`w-full h-screen bg-transparent
                    }`}
                        shadows={'variance'}
                        frameloop="demand"
                        gl={{
                            precision: 'highp',
                            powerPreference: 'high-performance',
                            // outputEncoding: sRGBEncoding,
                            pixelRatio: devicePixelRatio.toExponential,
                            outputEncoding: THREE.SRGBColorSpace,
                            localClippingEnabled: true,
                            preserveDrawingBuffer: true
                            // antialias: false, 
                            // toneMapping: true,
                            // alpha: true
                        }}
                        autoFocus
                        dpr={dpr}
                        colorManagement

                        // flat
                        // linear
                        shadowMap
                        orthographic={useOrthographicCamera}
                        camera={useOrthographicCamera ? { far: 1000, zoom: 100, position: [1, 50, 200], color: 'black' } : { near: 0.1, far: 10000, zoom: 1 }}
                    >
                        <PerformanceMonitor onIncline={() => setDpr(1.4)} onDecline={() => setDpr(1)} ></PerformanceMonitor>
                        {useRing && <Rig />}
                        {/* <Suspense fallback={<Loader></Loader>}> */}
                        {/* <THREE.CameraHelper></THREE.CameraHelper> */}
                        <Environment
                            // scene={}
                            // path='../accests'
                            files="/envv.hdr"
                        // shadows
                        // shadowMap
                        // preset='city'
                        // preset={night ? 'night' : 'apartment'}
                        />
                        {/* <PointerLockControls></PointerLockControls> */}


                        {/* <ambientLight ref={rectAreaLightRef} args={[color, intensity, width, height]} position={[0, 5, 0]} rotation-x={-Math.PI / 2} /> */}
                        {/* <ambientLight intensity={night ? 0 : 0.2} /> */}
                        <hemisphereLight
                            color="#010101"
                            skyColor="#fff"
                            groundColor="#fff"
                            intensity={night ? 0 : 1}
                            position={[0, 20, 0]}
                        />
                        <directionalLight
                            // color="0xfcfcfc"
                            // color="#fce192"
                            castShadow={true}
                            shadow-bias={-0.001}
                            // sRGBEncoding
                            // dispose={0}
                            // frustumCulled
                            // quaternion={20}

                            shadow-camera-left={-150}
                            shadow-camera-right={150}
                            shadow-camera-top={150}
                            shadow-camera-bottom={-150}

                            // position={[18, 12, 5]}
                            // position={[12, 14, 20]}
                            position={[20, 18, 0]}
                            angel={30}
                            intensity={night ? 0 : 8}
                            // intensity={0.1}
                            shadow-mapSize={512}
                        // shadow-radius={1}
                        // shadow-mapSize={[524, 524]}
                        >
                            <orthographicCamera attach="shadow-camera"
                                // args={[-15, 15, 15, -15, 2, 500]}
                                args={[-25, 25, 25, -25, 2, 500]}
                            />
                        </directionalLight>


                        {/* <pointLight position={[0, -10, 0]} intensity={1} /> */}
                        {useContactShadow && <ContactShadows position={[0, 0, 0]} opacity={0.75} scale={40} blur={1} far={10} />}
                        {0 && <group
                            position={[0, -.2, 2]}
                            rotation={[0, -0.785, 0]}>
                            {night &&
                                <group position={[0, 0, 0]}>
                                    <pointLight
                                        castShadow
                                        position={[4.9, 0.9, 10]}
                                        color="white"
                                        args={["#FFCF48", 0.5, 2, 5]}
                                    />
                                    <pointLight
                                        castShadow
                                        position={[3.9, 0.9, 10]}
                                        color="white"
                                        args={["#FFCF48", 0.5, 2, 4]}
                                    />
                                    <pointLight
                                        castShadow
                                        position={[3.9, 0.9, 9.4]}
                                        color="white"
                                        args={["#FFCF48", 1, 3, 5]}
                                    />
                                    <pointLight
                                        castShadow
                                        position={[9.9, 0.5, -3.1]}
                                        color="white"
                                        args={["#FFCF48", 0.7, 5, 1.5]}
                                    ></pointLight>
                                    <pointLight
                                        castShadow
                                        position={[11, 0.5, -3.1]}
                                        color="white"
                                        args={["#FFCF48", 0.7, 5, 1.5]}
                                    ></pointLight>
                                    <pointLight
                                        castShadow
                                        position={[9.9, 0.5, -4.2]}
                                        color="white"
                                        args={["#FFCF48", 0.7, 5, 1.5]}
                                    ></pointLight>
                                    <pointLight
                                        castShadow
                                        position={[11, 0.5, -4.5]}
                                        color="white"
                                        args={["#FFCF48", 0.7, 5, 1.5]}
                                    ></pointLight>
                                    {/* <spotLight
                                        castShadow
                                        position={[4.5, 2.4, 7.25]}
                                        rotation={[1,0,0]}
                                        power={15}
                                        angle={-1}
                                        color="white"
                                        // args={["#FFCF48", 1, 5, 5]}
                                    >
                                        <mesh>
                                            <sphereGeometry args={[0.05, 5, 5]} />
                                            <meshLambertMaterial color="green" roughness={0} metalness={0.1} />
                                        </mesh>
                                    </spotLight> */}
                                </group>
                            }
                            <Map
                                position={[0, 0, 0]}
                                scale={0.2}
                            />
                            <House
                                castShadow
                                position={[2, 0.19, -5]}
                                rotation={[0, 0, 0]}
                                scale={0.5}
                            // rotation={houseRotation}
                            />
                            <Med
                                position={[8, 0.11, 10]}
                                scale={0.25}
                            />
                            <Farm
                                position={[-11.4, 0.11, 2.3]}
                                scale={0.51}
                            />
                            {/* <RedSocks
                                position={[0, 0.25, 13]}
                                scale={0.45}
                            /> */}
                            <COD
                                position={[12, 0.12, -6]}
                                scale={0.35}
                            />
                            <CEX
                                position={[-6, 0.09, 4]}
                                rotation={[0, -1.575, 0]}
                                scale={0.4}
                            />
                            <PishProizvodstvo
                                rotation={[0, -1.57, 0]}
                                position={[-8.1, 0.11, -15.3]}
                                scale={0.4}
                            />
                            <Stadion
                                position={[2.1, 0.1, -16.8]}
                                rotation={[0, 1.548, 0]}
                                scale={0.9}
                            />
                            <Office
                                position={[-6, 0.1, -5.2]}
                                scale={1.5}
                            />
                        </group>
                        }
                        <group
                            position={[-2, -.2, 5]}
                            scale={0.2}
                            rotation={[0, -0.785, 0]}
                        >
                            {/* <Officce_n setFog={setFog} fog={fog} select={setSelectObject} /> */}

                            <Doroga_n fog={fog} />
                            
                            {/* <Stadion_n setFog={setFog} fog={fog} select={setSelectObject} /> */}
                            <Stadion_n2 setFog={setFog} fog={fog} select={setSelectObject} />

                            <Skad_n setFog={setFog} fog={fog} select={setSelectObject} />

                            {/* <Prom_n setFog={setFog} fog={fog} select={setSelectObject} /> */}
                            <Prom_n2 setFog={setFog} fog={fog} select={setSelectObject} />
                            
                            {/* <Ozelenenie_n setFog={setFog} fog={fog} select={setSelectObject} /> */}
                             {/* <Ozelenenie2 setFog={setFog} fog={fog} select={setSelectObject} /> */}
                             {/* <Ozelenenie_n2 setFog={setFog} fog={fog} select={setSelectObject} /> */}

                            <Fontan_n setFog={setFog} fog={fog}/>
                            <Svetofor_n setFog={setFog} fog={fog}/>
                            {/* <Fonari_n setFog={setFog} fog={fog} select={setSelectObject}/> */}
                            <Klumbi_n setFog={setFog} fog={fog}/>
                            <Dorogki_n setFog={setFog} fog={fog}/>
                            <Allei_n setFog={setFog} fog={fog} />
                            <Skameiki_n setFog={setFog} fog={fog} />
                            <Ploshadka_n setFog={setFog} fog={fog} />
                            <Derevo2_n setFog={setFog} fog={fog}/>
                            <Doma_masovka_n fog={fog}/>
{/*  */}
                            <Plane_n />

                            <Officce_n setFog={setFog} fog={fog} select={setSelectObject} />

                            {/* <Museum_n setFog={setFog} fog={fog} select={setSelectObject} /> */}
                            <Museum_n2 setFog={setFog} fog={fog} select={setSelectObject} />

                            <Hospital_n setFog={setFog} fog={fog} select={setSelectObject} />

                            {/* <Fabrika_n setFog={setFog} fog={fog} select={setSelectObject} /> */}
                            <Fabrika_n2 setFog={setFog} fog={fog} select={setSelectObject} />

                            {/* <Derevo_n fog={fog} /> */}
                            <Derevo_n2 fog={fog} />
                            
                            {/* <COD_n setFog={setFog} fog={fog} select={setSelectObject} /> */}
                            <COD_n2 setFog={setFog} fog={fog} select={setSelectObject} />

                            {/* <Fura_anim_n setFog={setFog} fog={fog} /> */}
                            <Car_all setFog={setFog} fog={fog} />
                            <Cran setFog={setFog} fog={fog}/> 
                        </group>



                        {/* <Planep
                            position={[0, 0, 0]}
                            scale={planeScale}
                        ></Planep> */}

                        {/* <Road
                            position={roadPosition}
                            scale={roadScale}
                            rotation={roadRotation}
                        /> */}

                        {/* <Boath/> */}
                        {/* <Bird /> */}
                        <OrbitControls
                            mouseButtons={{
                                LEFT: THREE.MOUSE.ROTATE,
                                RIGHT: THREE.MOUSE.ROTATE
                            }}
                            // maxAzimuthAngle={1.9}
                            // setAzimuthalAngle={1}
                            // minAzimuthAngle={0.9}
                            maxAzimuthAngle={1}
                            setAzimuthalAngle={-1}
                            minAzimuthAngle={-1.5}
                            maxDistance={45}
                            minDistance={45}
                            maxPolarAngle={1.2}
                            rotateSpeed={0.1}
                            panSpeed={0}
                            minPolarAngle={1.19}
                            // zoom0={60}
                            maxZoom={useOrthographicCamera ? 39 : 4}
                            minZoom={useOrthographicCamera ? 39 : 4}
                            zoomToCursor
                        />
                        {/* <OrthographicCamera
                            makeDefault
                            zoom={4}
                            bottom={-200}
                            left={200}
                            right={-200}
                            near={1}
                            far={50000}
                            position={[0, 0, 0]}
                        /> */}
                        {/* <axesHelper args={[500]} /> */}
                        {/* </Suspense> */}
                    </Canvas>
                </section>
            </div>
        </div>
    )
} 