import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Divider from '@mui/material/Divider';

export default function MainListItemsComponents(props) {
  const [select, setSelect] = React.useState(1)

  React.useEffect(() => {
    props.selected(select)
  }, [select])
  return (
    <React.Fragment>
      {/* <ListItemButton
        selected={select == 1 ? '1' : ''}
        onClick={() => setSelect(1)}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Меню" />
      </ListItemButton> */}
      <ListItemButton
        selected={select == 1 ? '1' : ''}
        onClick={() => setSelect(1)}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Оборудование" />
      </ListItemButton>
      <ListItemButton
        selected={select == 6 ? '1' : ''}
        onClick={() => setSelect(6)}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Группы обор." />
      </ListItemButton>
      <ListItemButton
        selected={select == 7 ? '1' : ''}
        onClick={() => setSelect(7)}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Записи обор." />
      </ListItemButton>




      <ListItemButton
        selected={select == 9 ? '1' : ''}
        onClick={() => setSelect(9)}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Запись<->Документы" />
      </ListItemButton>
      <Divider />

      <ListItemButton
        selected={select == 2 ? '1' : ''}
        onClick={() => setSelect(2)}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Решения" />
      </ListItemButton>

      <ListItemButton
        selected={select == 11 ? '1' : ''}
        onClick={() => setSelect(11)}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Реш.<->Документы" />
      </ListItemButton>

      <Divider />

      <ListItemButton
        selected={select == 3 ? '1' : ''}
        onClick={() => setSelect(3)}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="О компании" />
      </ListItemButton>

      <ListItemButton
        selected={select == 12 ? '1' : ''}
        onClick={() => setSelect(12)}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="О нас<->Документы" />
      </ListItemButton>

      <Divider />

      <ListItemButton
        selected={select == 4 ? '1' : ''}
        onClick={() => setSelect(4)}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Тех.поддержка" />
      </ListItemButton>

      <ListItemButton
        selected={select == 13 ? '1' : ''}
        onClick={() => setSelect(13)}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="ТП<->Документы" />
      </ListItemButton>

      <Divider />

      <ListItemButton
        selected={select == 5 ? '1' : ''}
        onClick={() => setSelect(5)}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Группа Реф." />
      </ListItemButton>
      
      <ListItemButton
        selected={select == 10 ? '1' : ''}
        onClick={() => setSelect(10)}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Записи Реф." />
      </ListItemButton>

      <Divider />
      <ListItemButton
        selected={select == 8 ? '1' : ''}
        onClick={() => setSelect(8)}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Документы" />
      </ListItemButton>


      {/* <ListItemButton
        selected={select == 3 ? '1' : ''}
        onClick={() => setSelect(3)}
      >
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Записи" />
      </ListItemButton> */}

      {/* <ListItemButton
        selected={select == 4 ? '1' : ''}
        onClick={() => setSelect(4)}
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Пользователи" />
      </ListItemButton> */}
    </React.Fragment>
  )
}

// export const secondaryListItems = (
//   <React.Fragment>
//     <ListSubheader component="div" inset>
//       Saved reports
//     </ListSubheader>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Current month" />
//     </ListItemButton>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Last quarter" />
//     </ListItemButton>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Year-end sale" />
//     </ListItemButton>
//   </React.Fragment>
// );