import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from "@react-spring/three";
import * as THREE from "three";
import ModelScene from "../accests/3d/museum_n2.glb";
import gsap from "gsap";

const Museum_n2 = (props) => {
  const { nodes, materials } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false);

  const colorChange = async () => {
    let key = Object.keys(materials);
    if (props.fog && !hovered)
      key.forEach((element) => {
        gsap.to(materials[element].emissive, 0.3, {
          r: 0.5,
          g: 0.5,
          b: 0.5,
        });
      });
    else {
      key.forEach((element) => {
        gsap.to(materials[element].emissive, 0.3, {
          r: 0,
          g: 0,
          b: 0,
        });
      });
    }
  };
  useEffect(() => {
    colorChange();
  }, [props.fog]);
  const modelRef = useRef();
  return (
    <a.group
      {...props}
      ref={modelRef}
      onPointerOver={(event) => {
        event.stopPropagation();
        hover(true);
        props.setFog(true);
      }}
      onPointerOut={(event) => {
        hover(false);
        props.setFog(false);
      }}
      onClick={()=>{
        props.select(1)
      }}
    >
      <group rotation={[0, -0.009, 0]} scale={[1.606, 1.77, 1.606]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007.geometry}
          material={materials.здание}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007_1.geometry}
          material={materials.ступеньки}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007_2.geometry}
          material={materials.ДВЕРИ}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007_3.geometry}
          material={materials.крыша}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007_4.geometry}
          material={materials.окна}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007_5.geometry}
          material={materials.перила}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007_6.geometry}
          material={materials.колонны}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007_7.geometry}
          material={materials['Material.002']}
        />
      </group>
    </a.group>
  );
};

export default Museum_n2;
