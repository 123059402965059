import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from "@react-spring/three";
import * as THREE from "three";
import ModelScene from "../accests/3d/cod_n2.glb";
import gsap from "gsap";

const COD_n2 = (props) => {
  const { nodes, materials } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false);

  const colorChange = async () => {
    let key = Object.keys(materials);
    if (props.fog && !hovered)
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5,
          });
      });
    else {
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0,
          });
      });
    }
  };
  useEffect(() => {
    colorChange();
  }, [props.fog]);
  const modelRef = useRef();
  return (
    <a.group
      {...props}
      ref={modelRef}
      onPointerOver={(event) => {
        event.stopPropagation();
        hover(true);
        props.setFog(true);
      }}
      onPointerOut={(event) => {
        hover(false);
        props.setFog(false);
      }}
      onClick={() => {
        props.select(2);
      }}
    >
      <group position={[53.772, 0, -37.954]} scale={1.193}>
        <group
          position={[-45.066, 0, 31.809]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-0.12, -1.289, -0.12]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube001.geometry}
            material={materials["здание.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube001_1.geometry}
            material={materials["окно.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube001_2.geometry}
            material={materials["двери.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube001_3.geometry}
            material={materials["синька.002"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text001.geometry}
          material={materials["Material.001"]}
          position={[-45.403, 0, 29.303]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text002.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -0.446, 29.752]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text003.geometry}
          material={materials["Material.001"]}
          position={[-45.4, 0, 31.694]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text004.geometry}
          material={materials["Material.001"]}
          position={[-45.4, 0, 31.466]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text005.geometry}
          material={materials["Material.001"]}
          position={[-45.4, 0, 31.092]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text006.geometry}
          material={materials["Material.001"]}
          position={[-45.4, 0, 30.847]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text007.geometry}
          material={materials["Material.001"]}
          position={[-45.4, 0, 31.713]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text008.geometry}
          material={materials["Material.001"]}
          position={[-45.4, 0, 31.055]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text009.geometry}
          material={materials["Material.001"]}
          position={[-45.4, 0, 30.874]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text010.geometry}
          material={materials["Material.001"]}
          position={[-45.4, 0, 29.761]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text011.geometry}
          material={materials["Material.001"]}
          position={[-45.4, 0, 30.4]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text012.geometry}
          material={materials["Material.001"]}
          position={[-45.4, 0, 29.964]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text013.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -0.446, 29.965]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text014.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -0.446, 30.162]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text015.geometry}
          material={materials["Material.001"]}
          position={[-45.4, 0, 30.199]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text016.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.448, 30.381]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text017.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -0.446, 30.587]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text018.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.448, 30.594]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text019.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.448, 30.822]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text020.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -0.446, 31.458]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text021.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.448, 31.233]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text022.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -0.446, 31.917]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text023.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -0.446, 32.114]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text024.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -1.75, 29.303]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text025.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.75, 31.694]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text026.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.75, 31.466]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text027.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.75, 31.092]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text028.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.75, 30.847]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text029.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.75, 31.713]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text030.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.75, 31.055]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text031.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.75, 30.874]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text032.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.75, 29.761]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text033.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.75, 30.4]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text034.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.75, 29.964]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text035.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.75, 30.199]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text036.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.448, 29.959]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text037.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -1.271, 29.752]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text038.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -1.271, 29.965]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text039.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -1.271, 30.162]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text040.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.273, 30.381]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text041.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -1.271, 30.587]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text042.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.273, 30.594]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text043.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.273, 30.822]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text044.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -1.271, 31.458]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text045.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.273, 31.233]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text046.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -1.271, 31.917]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text047.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -1.271, 32.114]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text048.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -1.273, 29.959]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text049.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -2.167, 29.752]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text050.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -2.167, 29.965]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text051.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -2.167, 30.162]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text052.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -2.169, 30.381]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text053.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -2.167, 30.587]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text054.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -2.169, 30.594]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text055.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -2.169, 30.822]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text056.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -2.167, 31.458]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text057.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -2.169, 31.233]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text058.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -2.167, 31.917]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text059.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -2.167, 32.114]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text060.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -2.169, 29.959]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text061.geometry}
          material={materials["Material.001"]}
          position={[-45.403, -0.807, 29.303]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text062.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.807, 31.694]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text063.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.807, 31.466]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text064.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.807, 31.092]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text065.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.807, 30.847]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text066.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.807, 31.713]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text067.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.807, 31.055]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text068.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.807, 30.874]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text069.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.807, 29.761]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text070.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.807, 30.4]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text071.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.807, 29.964]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text072.geometry}
          material={materials["Material.001"]}
          position={[-45.4, -0.807, 30.199]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.435}
        />
      </group>
    </a.group>
  );
};

export default COD_n2;
