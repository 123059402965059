import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from '@react-spring/three'
import * as THREE from 'three'
import ModelScene from '../accests/3d/skad_n.glb'
import gsap from "gsap";

const Skad_n = (props) => {
  const { nodes, materials } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false)

  const colorChange = async () => {
    let key = Object.keys(materials)
    if (props.fog && !hovered)
      key.forEach(element => {
        if (element != 'окна' && element != 'выступы')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5
          });
      });
    else {
      key.forEach(element => {
        if (element != 'окна' && element != 'выступы')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0
          });
      });
    }
  }
   
  useEffect(() => {
    colorChange()

  }, [props.fog])
  const modelRef = useRef()
  return (
    <a.group {...props} ref={modelRef}
      onPointerOver={(event) => {
        event.stopPropagation()
        hover(true)
        props.setFog(true)
      }
      }
      onPointerOut={(event) => {
        hover(false)
        props.setFog(false)
      }}
      onClick={()=>{
        props.select(6)
      }}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001.geometry}
        material={materials.окружение}
        position={[-14.494, 0.273, 32.399]}
        scale={18.274}
      />
      <group
        rotation={[Math.PI, -1.555, Math.PI]}
        scale={[-3.827, -0.147, -16.046]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube034.geometry}
          material={materials["здание.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube034_1.geometry}
          material={materials["окна.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube034_2.geometry}
          material={materials.платформа}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube034_3.geometry}
          material={materials.рама}
        />
      </group>
    </a.group>
  );
}

export default Skad_n;