import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as Chevron } from "../accests/chevron.svg";
import { CollapsibleComponents } from "./CollapsibleComponents";

import InnerHTML from "dangerously-set-html-content";
import axios from "axios";

export default function RMenu(props) {
  const [leftMenuW, setLeftMenuW] = useState("20rem");
  const [iconMenu, setIconMenu] = useState("->");
  const [itemOpen, setItemOpen] = useState(-1);
  const [more, setMore] = useState(-1);
  const [gallery, setGallery] = useState(false);
  const [category, setCategory] = useState(false);
  const myRef = useRef(null);
  const [navigationMenuVisibleState, setNavigationMenuVisibleState] =
    React.useState(false);
  // const executeScroll = () => myRef.current.scrollIntoView();
  const [refs, setResf] = useState([]);
  const handleClick = (id) => {
    if (refs.length > 0) {
      //console.log("select", refs[id]);
      refs[id].current.scrollIntoView({
        behavior: "smooth",
        // block: "start",
      });
    }
  };
  const handleCollapseLeftMenu = () => {
    setNavigationMenuVisibleState(!navigationMenuVisibleState);
    // if (leftMenuW == '20rem') {
    //     setLeftMenuW('0')
    // if (navigationMenuVisibleState)
    //     setIconMenu('->')
    // else{
    //     setIconMenu('<-')
    // }
    // } else {
    //     setLeftMenuW('20rem')
    //     setIconMenu('<-')s
    // }
  };
  useEffect(() => {
    if ("data" in props) {
      let buf = [];
      if (props.data)
        if (props.data.data) {
          props.data.data.map((acc, value) => {
            //console.log("refsss");
            acc[value.id] = React.createRef();
            buf.push(acc);
          });
          setResf(buf);
        }
    }
    //console.log("asdasd", props.selectMenu);
  }, []);
  useEffect(() => {
    //console.log("open", props.open);
    if (props.open - 1 != -1) {
      setItemOpen(props.open - 1);
      setMore(-1);
      //console.log("asd");
      // handleClick(props.open - 1);
    }
  }, [props.open]);

  const toggleGallery = (id) => {
    props.selectGallery(id);
    setGallery(!gallery);
  };

  const toggleCategory = () => {
    setCategory(!category);
  };
  // useEffect(()=>{
  //   alert(props.selectObject)
  //   if(props.selectObject){
  //     setItemOpen(props.selectObject)
  //   }
  // },[props.selectObject])

  // useEffect(() => {
  //   let buf = [];
  //   if ("data" in props) {
  //     if (props.data)
  //       if (props.data.data) {
  //         props.data.data.map((acc, value) => {
  //           //console.log("refsss");
  //           acc[value.id] = React.createRef();
  //           buf.push(acc);
  //         });
  //         setResf(buf);
  //       }
  //   }
  // }, [props.data]);

  const getDocumentsSolutions = async (element) => {
    const datat = await axios.get(`/api/documents/solutions/${element.id}`);
    let buf = element;
    if (datat.data) {
      buf["documents"] = datat.data;
    }
    props.setAloneItem(buf);
    // //console.log("buf", buf)
  };

  const getDocumentsCompany = async (element) => {
    const datat = await axios.get(`/api/documents/company/${element.id}`);
    let buf = element;
    if (datat.data) {
      buf["documents"] = datat.data;
    }
    props.setAloneItem(buf);
    // //console.log("buf", buf)
  };

  const getDocumentsSupports = async (element) => {
    const datat = await axios.get(`/api/documents/supports/${element.id}`);
    let buf = element;
    if (datat.data) {
      buf["documents"] = datat.data;
    }
    props.setAloneItem(buf);
    // //console.log("buf", buf)
  };

  return (
    <div
      className="RMenu"
      style={{
        background: "transparent",
        backgroundColor: "transparent",
        boxShadow: "none",
      }}
    >
      <div ref={myRef}>
        <p
          style={{
            padding: "0px 25px  25px",
            margin: "0px 0px 10px auto",
            textAlign: "end",
            width: "330px",
            fontSize: "32px",
          }}
        >
          {props.data?.name}
        </p>
        {props.data &&
          props.data.data.length > 0 &&
          props.data.data.map(
            (element, id) => {
              if (props.selectMenu === 5) {
                return (
                  <div
                    className="element"
                    style={{
                      position: "relative",
                      minHeight: "60px",
                      padding: "20px",
                      margin: "0 0 10px auto",
                      width: "300px",
                      borderTopLeftRadius: "50px",
                      borderBottomRightRadius: "50px",
                      boxShadow: "4px 4px 8px 0 rgba(34,60,80,.2)",
                      backgroundColor:
                        id % 2 != 0
                          ? "rgba(255, 255, 255, 0.8)"
                          : "rgba(5, 112, 184, 0.8)",
                      listStyle: "none",
                      cursor: "pointer",
                    }}
                  >
                    <div>
                      <h3
                        style={{ color: id % 2 != 0 ? "black" : "white" }}
                        onClick={() => {
                          toggleGallery(element.id);
                        }}
                      >
                        {element?.name ?? ""}
                      </h3>
                    </div>
                  </div>
                );
              } else if (props.selectMenu === 1) {
                return (
                  <div
                    className="element"
                    style={{
                      position: "relative",
                      minHeight: "60px",
                      padding: "20px",
                      margin: "0 0 10px auto",
                      width: "300px",
                      borderTopLeftRadius: "50px",
                      borderBottomRightRadius: "50px",
                      boxShadow: "4px 4px 8px 0 rgba(34,60,80,.2)",
                      backgroundColor:
                        id % 2 != 0
                          ? "rgba(255, 255, 255, 0.8)"
                          : "rgba(5, 112, 184, 0.8)",
                      listStyle: "none",
                      cursor: "pointer",
                    }}
                  >
                    <div>
                      <h3
                        style={{
                          color:
                            id % 2 != 0 ? "black" : "white",
                        }}
                        onClick={() => {
                          props.setCategoryData(element);
                          props.setCategory(true);
                        }}
                      >
                        {element?.name ?? ""}
                      </h3>
                    </div>
                  </div>
                );
              } else if (props.selectMenu === 3) {
                return (
                  <div
                    ref={refs[element.id]}
                    className="element"
                    // onClick={toggle}
                    onClick={() => {
                      if (itemOpen === id) {
                        alert("1");
                        setItemOpen(-1);
                        // props.selectOpen
                        props.setSelectObject(-1);
                        setMore(-1);
                      } else {
                        setItemOpen(id);
                        setMore(-1);
                      }
                    }}
                    style={{
                      position: "relative",
                      minHeight: "60px",
                      // height:'272px',
                      padding: "20px",
                      margin: "0 0 10px auto",
                      width: 0 ? "800px" : "300px",
                      borderTopLeftRadius: "50px",
                      borderBottomRightRadius: "50px",
                      boxShadow: "4px 4px 8px 0 rgba(34,60,80,.2)",
                      color: id % 2 != 0 ? "black" : "white",

                      backgroundColor:
                        id % 2 != 0
                          ? "rgba(255, 255, 255, 0.8)"
                          : "rgba(5, 112, 184, 0.8)",
                      listStyle: "none",
                      cursor: "pointer",
                    }}
                  >
                    <div>
                      <h3 style={{ color: id % 2 != 0 ? "black" : "white" }}>
                        {element.name ?? ""}
                      </h3>
                    </div>
                    {id === itemOpen && (
                      <div className="toggle">
                        <p>{element?.text ?? ""}</p>
                        <br></br>
                        {element?.description && (
                          <a
                            style={{ textDecoration: "underline" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              getDocumentsSolutions(element)
                              // props.setAloneItem(buf);

                              // if (more == id) {
                              //   setMore(-1);
                              // } else setMore(id);
                            }}
                          >
                            Подробнее
                          </a>
                        )}
                      </div>
                    )}
                    {more == id && (
                      <div className="toggleMore">
                        <p>{element?.description ?? ""}</p>
                      </div>
                    )}
                  </div>
                );
              } else if (props.selectMenu === 4) {
                return (
                  <div
                    ref={refs[element.id]}
                    className="element"
                    // onClick={toggle}
                    onClick={() => {
                      if (itemOpen === id) {
                        setItemOpen(-1);
                        props.setSelectObject(-1);
                        setMore(-1);
                      } else {
                        setItemOpen(id);
                        setMore(-1);
                      }
                    }}
                    style={{
                      position: "relative",
                      minHeight: "60px",
                      // height:'272px',
                      padding: "20px",
                      margin: "0 0 10px auto",
                      width: 0 ? "800px" : "300px",
                      borderTopLeftRadius: "50px",
                      borderBottomRightRadius: "50px",
                      boxShadow: "4px 4px 8px 0 rgba(34,60,80,.2)",
                      // color: id % 2 != 0 ? "black" : "white",
                      color: id % 2 != 0 ? "black" : "white",

                      backgroundColor:
                        id % 2 == 0
                          ? "rgba(5, 112, 184, 0.8)"
                          : "rgba(255, 255, 255, 0.8)",
                      listStyle: "none",
                      cursor: "pointer",
                    }}
                  >
                    <div>
                      <h3 style={{ color:  id % 2 != 0 ? "black" : "white" }}>
                        {element.name ?? ""}
                      </h3>
                    </div>
                    {id === itemOpen && (
                      <div className="toggle">
                        <p>{element?.text ?? ""}</p>
                        <br></br>
                        {element?.description && (
                          <a
                            style={{ textDecoration: "underline" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              getDocumentsSupports(element)

                              // if (more == id) {
                              //   setMore(-1);
                              // } else setMore(id);
                            }}
                          >
                            Подробнее
                          </a>
                        )}
                      </div>
                    )}
                    {more == id && (
                      <div className="toggleMore">
                        <p>{element?.description ?? ""}</p>
                      </div>
                    )}
                  </div>
                );
              } else if (props.selectMenu === 2) {
                return (
                  <div
                    ref={refs[element.id]}
                    className="element"
                    // onClick={toggle}
                    onClick={() => {
                      if (itemOpen === id) {
                        setItemOpen(-1);
                        props.setSelectObject(-1);
                        setMore(-1);
                      } else {
                        setItemOpen(id);
                        setMore(-1);
                      }
                    }}
                    style={{
                      position: "relative",
                      minHeight: "60px",
                      // height:'272px',
                      padding: "20px",
                      margin: "0 0 10px auto",
                      width: 0 ? "800px" : "300px",
                      borderTopLeftRadius: "50px",
                      borderBottomRightRadius: "50px",
                      boxShadow: "4px 4px 8px 0 rgba(34,60,80,.2)",
                      color: id % 2 != 0 ? "black" : "white",

                      backgroundColor:
                        id % 2 != 0
                          ? "rgba(255, 255, 255, 0.8)"
                          : "rgba(5, 112, 184, 0.8)",
                      listStyle: "none",
                      cursor: "pointer",
                    }}
                  >
                    <div>
                      <h3 style={{ color: id % 2 != 0 ? "black" : "white" }}>
                        {element.name ?? ""}
                      </h3>
                    </div>
                    {id === itemOpen && (
                      <div className="toggle">
                        <p>{element?.text ?? ""}</p>
                        <br></br>
                        {element?.description && (
                          <a
                            style={{ textDecoration: "underline" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              getDocumentsCompany(element)

                              // if (more == id) {
                              //   setMore(-1);
                              // } else setMore(id);
                            }}
                          >
                            Подробнее
                          </a>
                        )}
                      </div>
                    )}
                    {more == id && (
                      <div className="toggleMore">
                        <p>{element?.description ?? ""}</p>
                      </div>
                    )}
                  </div>
                );
              } else if (id != more) {
                return (
                  <div
                    ref={refs[element.id]}
                    className="element"
                    // onClick={toggle}
                    onClick={() => {
                      if (itemOpen === id) {
                        setItemOpen(-1);
                        props.setSelectObject(-1);
                        setMore(-1);
                      } else {
                        setItemOpen(id);
                        setMore(-1);
                      }
                    }}
                    style={{
                      position: "relative",
                      minHeight: "60px",
                      // height:'272px',
                      padding: "20px",
                      margin: "0 0 10px auto",
                      width: 0 ? "800px" : "300px",
                      borderTopLeftRadius: "50px",
                      borderBottomRightRadius: "50px",
                      boxShadow: "4px 4px 8px 0 rgba(34,60,80,.2)",
                      color: id % 2 != 0 ? "black" : "white",

                      backgroundColor:
                        id % 2 != 0
                          ? "rgba(255, 255, 255, 0.8)"
                          : "rgba(5, 112, 184, 0.8)",
                      listStyle: "none",
                      cursor: "pointer",
                    }}
                  >
                    <div>
                      <h3 style={{ color: id % 2 != 0 ? "black" : "white" }}>
                        {element.name ?? ""}
                      </h3>
                    </div>
                    {id === itemOpen && (
                      <div className="toggle">
                        <p>{element?.text ?? ""}</p>
                        <br></br>
                        {element?.description && (
                          <a
                            style={{ textDecoration: "underline" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (more == id) {
                                setMore(-1);
                              } else setMore(id);
                            }}
                          >
                            Подробнее
                          </a>
                        )}
                      </div>
                    )}
                    {more == id && (
                      <div className="toggleMore">
                        <p>{element?.description ?? ""}</p>
                      </div>
                    )}
                  </div>
                );
              } else {
                return (
                  <div
                    // className="element"
                    // onClick={toggle}

                    style={{
                      position: "relative",
                      minHeight: "300px",
                      // padding: '20px',
                      margin: "0 0 10px auto",
                      width: "70vw",
                      maxHeight: "600px",
                      borderTopLeftRadius: "50px",
                      color: id % 2 != 0 ? "black" : "white",
                      borderBottomRightRadius: "50px",
                      boxShadow: "4px 4px 8px 0 rgba(34,60,80,.2)",
                      backgroundColor:
                        id % 2 != 0
                          ? "rgba(255, 255, 255, 0.8)"
                          : "rgba(5, 112, 184, 0.8)",
                      listStyle: "none",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      onClick={() => {
                        if (itemOpen === id) {
                          setItemOpen(-1);
                          props.setSelectObject(-1);
                          setMore(-1);
                        } else {
                          setItemOpen(id);
                          setMore(-1);
                        }
                      }}
                    >
                      <h3
                        // onClick={}
                        style={{
                          color: "black",
                          position: "absolute",
                          left: "2em",
                          top: "15px",
                          background: "#FFFFFF66",
                          borderRadius: "10px",
                          padding: "5px",
                        }}
                      >
                        {element?.name ?? ""}
                      </h3>
                    </div>
                    <div className="toggleMore">
                      <div
                        className="togglePhoto"
                        onClick={() => {
                          if (itemOpen === id) {
                            setItemOpen(-1);
                            props.setSelectObject(-1);
                            setMore(-1);
                          } else {
                            setItemOpen(id);
                            setMore(-1);
                          }
                        }}
                        // onClick={toggle}
                        style={{
                          height: "200px",
                          borderTopLeftRadius: "50px",
                          backgroundImage: `url("${element?.img}")`,
                          backgroundSize: "100%",
                        }}
                      ></div>
                      <div
                        className="toggleMoreText"
                        style={{
                          padding: "20px 30px",
                          overflow: "auto",
                          maxHeight: "400px",
                        }}
                      >
                        <InnerHTML html={element?.description}></InnerHTML>
                      </div>
                    </div>
                  </div>
                );
              }
            }

            // <CollapsibleComponents
            //   id={id}
            //   selectGallery={0}
            //   selectMenu={1}
            //   setOpenItem = {setItemOpen}
            //   open={itemOpen===id? '1':'0'}
            //   // open={props.open!=null && props.open!=8 && element.open-1===id? '1':'0'}
            //   element={element}
            // ></CollapsibleComponents>
          )}
        {/* {props.data == null && <h4>Записей еще нет</h4>} */}
      </div>
    </div>
  );
}
