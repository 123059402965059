import React, { useRef, useEffect, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from "@react-spring/three";
import * as THREE from "three";
import ModelScene from "../accests/3d/auto_all.glb";
import gsap from "gsap";

const Car_all = (props) => {
  const { nodes, materials, animations } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false);
  const modelRef = useRef();
  const { actions } = useAnimations(animations, modelRef);

  useEffect(() => {
    actions["машинка.001Action"].play();
    actions["Cube.006Action"].play();
    // actions['Cube.041Action.001'].play();
    // actions["].play();
  }, []);
  const colorChange = async () => {
    let key = Object.keys(materials);
    // //console.log(key)
    if (props.fog && !hovered)
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5,
          });
      });
    else {
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0,
          });
      });
    }
  };
  useEffect(() => {
    colorChange();
  }, [props.fog]);
  return (
    <a.group {...props} ref={modelRef}>
      <group name="Scene">
        <mesh
          name="машинка001"
          castShadow
          receiveShadow
          geometry={nodes.машинка001.geometry}
          material={materials.машинка}
          position={[1.527, 1.066, -41.676]}
          rotation={[0, -1.567, 0]}
          scale={[0.818, 1, 1]}
        />
        <group
          name="Cube006"
          position={[56.622, 1.367, 49.001]}
          rotation={[0, -1.571, 0]}
          scale={[0.051, 0.098, 1.095]}>
          <mesh
            name="Cube002"
            castShadow
            receiveShadow
            geometry={nodes.Cube002.geometry}
            material={materials['окружение.001']}
          />
          <mesh
            name="Cube002_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube002_1.geometry}
            material={materials['Material.011']}
          />
        </group>
      </group>
    </a.group>
  );
};

export default Car_all;
