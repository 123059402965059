import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from "@react-spring/three";
import * as THREE from "three";
import ModelScene from "../accests/3d/doma_masovka_n.glb";
import gsap from "gsap";

const Doma_masovka_n = (props) => {
  const { nodes, materials } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false);

  const colorChange = async () => {
    let key = Object.keys(materials);
    if (props.fog && !hovered)
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5,
          });
      });
    else {
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0,
          });
      });
    }
  };
  useEffect(() => {
    colorChange();
  }, [props.fog]);
  const modelRef = useRef();
  return (
    <a.group
      {...props}
      ref={modelRef}
      
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        material={materials.окружение}
        position={[-17.852, 5.529, 10.73]}
        scale={[0.554, 0.102, 0.554]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002.geometry}
        material={materials.окружение}
        position={[54.174, 5.529, -49.673]}
        scale={[0.554, 0.102, 0.554]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004.geometry}
        material={materials.окружение}
        position={[-25.316, 1.8, -75.807]}
        scale={[1.632, 1.242, 1.242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube011.geometry}
        material={materials.окружение}
        position={[-23.949, 5.529, 10.73]}
        scale={[0.554, 0.102, 0.554]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube015.geometry}
        material={materials.окружение}
        position={[-58.589, 9.666, -22.125]}
        scale={[0.618, 0.214, 0.618]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube026.geometry}
        material={materials.окружение}
        position={[-68.663, 4.172, -0.235]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={[-0.519, -0.129, -0.519]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube027.geometry}
        material={materials.окружение}
        position={[-42.922, 7.552, -22.952]}
        scale={[0.1, 0.508, 2.453]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube029.geometry}
        material={materials.окружение}
        position={[-54.71, 14.799, -30.697]}
        rotation={[0, 1.571, 0]}
        scale={[1.553, 0.77, 0.061]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube034.geometry}
        material={materials.окружение}
        position={[-76.611, 14.787, -30.781]}
        rotation={[0, 1.571, 0]}
        scale={[1.553, 0.77, 0.061]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube044.geometry}
        material={materials.окружение}
        position={[-48.269, 7.22, -36.309]}
        scale={[0.094, 0.639, 2.3]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube045.geometry}
        material={materials.окружение}
        position={[-45.54, 1.926, -30.568]}
        rotation={[0, 0.034, 0]}
      />
    </a.group>
  );
};

export default Doma_masovka_n;
