import React from 'react'
import logo from '../accests/logo.png'

export default function Header() {
    return (
        <header className='header' style={{ background: 'transparent' }}>
            <div className='headerContainer'>
                <div className='headerLogo'>
                    <img src={logo} style={{ 'width': '256px', 'marginTop': '10px' }} />
                </div>
                <div className='headerButton'>
                    <div className='loginButton'>

                    </div>
                </div>
            </div>
        </header>
    )
}
