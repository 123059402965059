import React, { useEffect, useState } from "react";
import axios from "axios";
import { ReactComponent as Chevron } from "../accests/chevron.svg";

export default function LMenu(props) {
  const [leftMenuW, setLeftMenuW] = useState("20rem");
  const [selectMenu, setSelectMenu] = useState(null);
  const [iconMenu, setIconMenu] = useState("->");
  const [navigationMenuVisibleState, setNavigationMenuVisibleState] =
    React.useState(false);
  const handleCollapseLeftMenu = () => {
    setNavigationMenuVisibleState(!navigationMenuVisibleState);
    setSelectMenu(0);
    props.setOpen();
    // if (leftMenuW == '20rem') {
    //     setLeftMenuW('0')
    // if (navigationMenuVisibleState)
    //     setIconMenu('->')
    // else{
    //     setIconMenu('<-')
    // }
    // } else {
    //     setLeftMenuW('20rem')
    //     setIconMenu('<-')
    // }
  };

  const getCategory = async () => {
    const datat = await axios.get("/api/solutions_posts/");
    if (datat.status === 200) {
      let bufRows = [];
      let bufHeaders = [];
      for (let row of datat.data) {
        bufRows.push(row);
      }
      props.setDataRMenu({ name: "Решения", data: bufRows });
      handleCollapseLeftMenu();
    }
  };
  const getAbout = async () => {
    const datat = await axios.get("/api/companys_posts/");
    if (datat.status === 200) {
      let bufRows = [];
      let bufHeaders = [];
      for (let row of datat.data) {
        bufRows.push(row);
      }
      props.setDataRMenu({ name: "О компании", data: bufRows });
      handleCollapseLeftMenu();
    }
  };
  const getHelping = async () => {
    const datat = await axios.get("/api/supports_posts/");
    if (datat.status === 200) {
      let bufRows = [];
      let bufHeaders = [];
      for (let row of datat.data) {
        bufRows.push(row);
      }
      props.setDataRMenu({ name: "Техническая поддержка", data: bufRows });
      handleCollapseLeftMenu();
    }
  };
  const getMaking = async () => {
    const datat = await axios.get("/api/equipments/");
    if (datat.status === 200) {
      let bufRows = [];
      let bufHeaders = [];
      //console.log(datat);
      for (let row of datat.data) {
        bufRows.push(row);
      }
      props.setDataRMenu({ name: "Оборудование", data: bufRows });
      handleCollapseLeftMenu();
    }
  };
  const getRef = async () => {
    const datat = await axios.get("/api/references_groups/");
    if (datat.status === 200) {
      let bufRows = [];
      let bufHeaders = [];
      for (let row of datat.data) {
        bufRows.push(row);
      }
      props.setDataRMenu({ name: "Референции", data: bufRows });
      handleCollapseLeftMenu();
    }
  };
  useEffect(() => {
    if (selectMenu === 1) {
      props.setDataIdLMenu(1)
      getMaking();
    } else if (selectMenu === 2) {
      props.setDataIdLMenu(2)
      getAbout();
    } else if (selectMenu === 3) {
      props.setDataIdLMenu(3)
      getCategory();
    } else if (selectMenu === 4) {
      props.setDataIdLMenu(4)
      getHelping();
    } else if (selectMenu === 5) {
      props.setDataIdLMenu(5)
      getRef();
    }
  }, [selectMenu]);
  useEffect(() => {
    if (!props.open) {
      setNavigationMenuVisibleState(false);
      setSelectMenu(0);
    }
  }, [props.open]);
  return (
    <div>
      <button
        onClick={handleCollapseLeftMenu}
        style={{
          transform: navigationMenuVisibleState
            ? "translate3d(0, 0, 0)"
            : "translate3d(-570%, 0, 0)",
          transition: "transform 0.3s ease-in-out",
          border: "solid black 0px",
          height: "35px",
          left: "14rem",
          "z-index": "20000",
          // 'left': leftMenuW == '20rem' ? '15rem' : '0px',
          width: "40px",
          top: "5.5rem",
          cursor: "pointer",
          padding: '0px',
          background: "rgb(5, 112, 184)",
          borderTopLeftRadius: "50px",
          borderBottomRightRadius: "50px",
          borderRadius: "0 100% 100% 0 / 0 100% 100% 0",
          position: "fixed",
        }}
      >
        <Chevron
          width={"25px"}
          height={"25px"}
          style={{
            transform: navigationMenuVisibleState
              ? "rotate(540deg)"
              : "rotate(0)",
            transition: "all 0.8s",
          }}
        />

        {/* <i
                    style={{
                        'fontSize': '26px',
                        'position': 'absolute',
                        'top': '-3px',
                        'left': '0',
                        'transform': navigationMenuVisibleState ? 'rotate(0)' : 'rotate(540deg)',
                        'transition': 'all 0.8s'

                    }}
                    class="large material-icons">
                   <chevron></chevron>
                </i> */}
      </button>
      <ui
        id=""
        className={navigationMenuVisibleState ? "leftMenu visible" : "leftMenu"}
        style={{
          width: "17rem",
          background: "transparent ",
          backgroundColor: "transparent",
          boxShadow: "none",
          zIndex: "10000",
        }}
      >
        <li
          style={{
            margin: "8px",
            width: "13rem",
            marginLeft: "0px",
            borderRadius: "0px 36px 36px 0px ",
            backgroundColor: "transparent",
            marginBottom: "30px",
            listStyle: "none",
          }}
        >
          <h3
            className="menuItemContenth3"
            style={{ color: "black", marginLeft: "20px", cursor: "default" }}
          >
            Меню
          </h3>
        </li>
        <li
          onClick={() => {
            setSelectMenu(2);
            //setNameRMenu('О компании')
          }}
          className="LeftItem"
          style={{
            margin: "8px",
            marginLeft: "0px",
            borderTopLeftRadius: "50px",
            borderBottomRightRadius: "50px",
            backgroundColor: "rgba(5, 112, 184, 0.5)",
            listStyle: "none",
            cursor: "pointer",
          }}
        >
          <a
            className="menuItem"
            style={{ borderRadius: "0px 36px 36px 0px ", border: "none" }}
          >
            <div className="menuItemContent">
              <h3 className="menuItemContenth3" style={{ color: "black" }}>
                О компании
              </h3>
            </div>
          </a>
        </li>
        <li
          onClick={() => {
            setSelectMenu(1);
            //setNameRMenu('Оборудование')
          }}
          className="LeftItem"
          style={{
            margin: "8px",
            marginLeft: "0px",
            borderTopLeftRadius: "50px",
            borderBottomRightRadius: "50px",
            backgroundColor: "rgba(255,255,255, 0.5)",
            listStyle: "none",
            cursor: "pointer",
          }}
        >
          <a
            className="menuItem"
            style={{ borderRadius: "0px 36px 36px 0px ", border: "none" }}
          >
            <div className="menuItemContent">
              <h3 className="menuItemContenth3" style={{ color: "black" }}>
                Оборудование
              </h3>
            </div>
          </a>
        </li>
        
        <li
          onClick={() => {
            setSelectMenu(3);
            //setNameRMenu('Решения')
          }}
          className="LeftItem"
          style={{
            margin: "8px",
            marginLeft: "0px",
            borderTopLeftRadius: "50px",
            borderBottomRightRadius: "50px",
            backgroundColor: "rgba(5, 112, 184, 0.5)",
            listStyle: "none",
            cursor: "pointer",
          }}
        >
          <a
            className="menuItem"
            style={{ borderRadius: "0px 36px 36px 0px ", border: "none" }}
          >
            <div className="menuItemContent">
              <h3 className="menuItemContenth3" style={{ color: "black" }}>
                Решения
              </h3>
            </div>
          </a>
        </li>
        <li
          onClick={() => {
            setSelectMenu(4);
            //setNameRMenu('Техническая поддержка')
          }}
          className="LeftItem"
          style={{
            margin: "8px",
            marginLeft: "0px",
            borderTopLeftRadius: "50px",
            borderBottomRightRadius: "50px",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            listStyle: "none",
            cursor: "pointer",
          }}
        >
          <a
            className="menuItem"
            style={{ borderRadius: "0px 36px 36px 0px ", border: "none" }}
          >
            <div className="menuItemContent">
              <h3 className="menuItemContenth3" style={{ color: "black" }}>
                Техническая
                <br />
                поддержка
              </h3>
            </div>
          </a>
        </li>
        <li
          onClick={() => {
            setSelectMenu(5);
            //setNameRMenu('Референции')
          }}
          className="LeftItem"
          style={{
            margin: "8px",
            marginLeft: "0px",
            borderTopLeftRadius: "50px",
            borderBottomRightRadius: "50px",
            backgroundColor: "rgba(5, 112, 184, 0.5)",
            listStyle: "none",
            cursor: "pointer",
          }}
        >
          <a
            className="menuItem"
            style={{ borderRadius: "0px 36px 36px 0px ", border: "none" }}
          >
            <div className="menuItemContent">
              <h3 className="menuItemContenth3" style={{ color: "black" }}>
                Референции
              </h3>
            </div>
          </a>
        </li>
      </ui>
    </div>
  );
}
