import React, { useEffect, useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { AuthContext } from '../context/AuthContext'
import { useHttp } from '../hooks/http.hook'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import DraftEditor from './Editor/DraftEditor';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
function preventDefault(event) {
    event.preventDefault();
}

export default function ReferenceGroupCreateComponents(props) {

    const { token, logout } = useContext(AuthContext)
    const [title, setTitle] = useState(null)
    const [text, setText] = useState(null)
    const [photo, setPhoto] = useState(null)
    const [reload, setReload] = useState(null)
    const [categoryRows, setCategoryRows] = useState(new Map())
    const [options, setOptions] = useState([])
    const [valueDropDown, setValueDropDown] = useState(null)

    const { loading, request, error, clearError } = useHttp()


    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link'],
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
            [{ 'image': [] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ]
    }
    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "color",
    ]

    const getCategory = async () => {
        const datat = await request('/api/reference', 'GET', null, {
            authorization: `${token}`
        })
        if (datat) {
            let bufRows = []
            let bufRows2 = []
            //console.log(datat)
            for (let row of datat) {
                bufRows.push({ 'label': row.title, 'value': row._id })
                bufRows2.push({ 'title': row.title, 'id': row._id })
            }
            setCategoryRows(new Map(bufRows2.map(item => [item.id, item.title])))
            setOptions(bufRows)

        }
    }

    React.useEffect(() => {
        if (props?.select) {
            //console.log()
            setTitle(props?.select?.name ?? '')
            setText(props?.select?.text ?? '')
            setPhoto(props?.select?.photo ?? '')
            setValueDropDown(categoryRows.get(props.select.categori))

        }

    }, [props, categoryRows])

    React.useEffect(() => {
        // getCategory()
    }, [])

    React.useEffect(() => {
        if (reload) {
            props.update()
            setTitle('')
            setText('')
            setPhoto('')
            setValueDropDown(null)
            setReload(null)

        }
    }, [reload])

    useEffect(() => {
        //console.log("select", valueDropDown)
    }, [valueDropDown])

    const updateRow = async () => {
        if (props?.select && (props?.select?.name != title)) {
            const data = await request(`/api/references_groups/${props.select.id}`, 'PUT', { name: title }, {
                authorization: `${token}`
            })
            setReload(1)
        }

    }

    const createRow = async () => {
        if (title) {
            const data = await request(`/api/references_groups/`, 'POST', { name: title }, {
                authorization: `${token}`
            })
            setReload(1)

        }

    }
    const deleteRow = async () => {
        if (title) {
            const data = await request(`/api/references_groups/${props.select.id}`, 'DELETE', null, {
                authorization: `${token}`
            })
            setReload(1)
        }

    }

    // useEffect(()=>{
    //     //console.log((props?.select?.text!=text && props?.select?.title!=title))
    // },[text, title])

    return (
        <React.Fragment>
            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="TitleTextField"
                        // label={title?"Заголовок":""
                        variant="standard"
                        placeholder='Заголовок'
                        // disabled={props?.select ? "" : "1"}
                        value={title}
                        onChange={(e) => { setTitle(e.target.value) }} />
                </Grid>
                <Grid item xs={5}>
                    <Button variant="contained"
                        onClick={updateRow}
                        disabled={(props?.select && (props?.select?.name != title)) ? "" : "1"}>Редактировать</Button>
                </Grid>
                <Grid item xs={5}>
                    <Button variant="contained"

                        onClick={createRow}
                    >Добавить</Button>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained"
                        color="error"
                        onClick={deleteRow}
                        disabled={(props?.select) ? "" : "1"}
                    ><DeleteForeverIcon></DeleteForeverIcon></Button>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}