import React, { Suspense, useRef, useState } from "react";
import * as THREE from "three";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import Loader from "./components/Loader";
import House from "./models/House";
import { Bird } from "./models/Bird";
import {
  ContactShadows,
  Environment,
  OrbitControls,
  OrthographicCamera,
  PerformanceMonitor,
  Stage,
  useHelper,
} from "@react-three/drei";
import { AxesHelper } from "three";
import { useControls } from "leva";
import Planep from "./models/Plane";
import Road from "./models/Road";
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper";
import Map from "./models/Map";
import Med from "./models/Med";
import Farm from "./models/Farm";
import RedSocks from "./models/RedSocks";
import { Boath } from "./models/Boath";
import COD from "./models/COD";
import { sRGBEncoding } from "three";
import CEX from "./models/CEX";
import PishProizvodstvo from "./models/PishProizvodstvo";
import Stadion from "./models/Stadion";
import Main from "./pages/Main";
import { AuthContext } from "./context/AuthContext";
import { useAuth } from "./hooks/auth.hook";
import { useRoutes } from "./routes";
import { BrowserRouter as Router} from 'react-router-dom'
const useOrthographicCamera = true;
const useContactShadow = true;
const useShadow = true;
const useRing = true;
const night = false;


const App = () => {
  const { token, login, logout, userId, ready } = useAuth();
  const isAuthenticated = !!token;
  const routes = useRoutes(isAuthenticated);
  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        logout,
        userId,
        isAuthenticated,
      }}
    >
      <Router>{routes}</Router>
    </AuthContext.Provider>
  );
  // return(
  //     <Main/>
  // )
  // const rectAreaLightRef = useRef();
  // const reactDire = useRef();
  // const [hovered, hover] = useState(false)
  // const adjustHouseScreenSize = () => {
  //     let screenScale = null;
  //     // let screenPosition = [0, -5, -20];
  //     let screenPosition = [0, 0.07, 0];
  //     let rotation = [0.1, 10.7, 0];
  //     if (window.innerHeight < 768) {
  //         screenScale = [0.3, 0.3, 0.3];
  //     } else {
  //         screenScale = [.25, .25, .25];
  //     }
  //     return [screenScale, screenPosition, rotation];
  // }
  // const adjustPlaneScreenSize = () => {
  //     let screenScale = null;
  //     let screenPosition = [0, 0, 10];
  //     if (window.innerHeight < 768) {
  //         screenScale = [0.9, 0.9, 0.9];
  //     } else {
  //         screenScale = [1, 1, 1];
  //     }
  //     return [screenScale, screenPosition];
  // }
  // const adjustRoadScreenSize = () => {
  //     let screenScale = null;
  //     let screenPosition = [-2, -0.02, 0];
  //     let screenRotation = [0, -1, 0];
  //     if (window.innerHeight < 768) {
  //         screenScale = [.3, .3, .3];
  //     } else {
  //         screenScale = [.3, .3, .3];
  //     }
  //     return [screenScale, screenPosition, screenRotation];
  // }
  // const [houseSacel, housePosition, houseRotation] = adjustHouseScreenSize();
  // const [planeScale, planePosition] = adjustPlaneScreenSize();
  // const [roadScale, roadPosition, roadRotation] = adjustRoadScreenSize();

  // // const { intensity, width, height, color } = useControls({ intensity: { value: 1, min: 0, max: 20 }, width: { value: 3, min: 1, max: 10 }, height: { value: 3, min: 1, max: 10 }, color: '#fff' });
  // // const { intensity2, width2, height2, color2 } = useControls({ intensity: { value: 1, min: 0, max: 20 }, width: { value: 3, min: 1, max: 10 }, height: { value: 3, min: 1, max: 10 }, color: '#fff' });

  // function Rig() {
  //     const { camera, mouse } = useThree()
  //     const vec = new THREE.Vector3()
  //     return useFrame(() => {
  //         camera.position.lerp(vec.set(mouse.x * 2, mouse.y * 18, camera.position.z), 0.05)
  //     })
  // }

  // // const controls = new PointerLockControls( camera, document.body );
  // const [dpr, setDpr] = useState(1.5)
  // return (
  //     <div>
  //         <section className='w-full h-screen relative'>
  //             <Canvas
  //                 style={{ background: night ? "gray" : "white" }}
  //                 className={`w-full h-screen bg-transparent
  //                 }`}
  //                 shadows={'variance'}
  //                 frameloop="demand"
  //                 gl={{
  //                     // outputEncoding: sRGBEncoding,
  //                     // pixelRatio: devicePixelRatio.toExponential,
  //                     localClippingEnabled: true
  //                 }}
  //                 dpr={dpr}
  //                 colorManagement

  //                 // shadowMap
  //                 orthographic={useOrthographicCamera}
  //                 camera={useOrthographicCamera ? { far: 1000, zoom: 100, position: [1, 50, 200], color: 'black' } : { near: 0.1, far: 10000, zoom: 1 }}
  //             >
  //                 <PerformanceMonitor onIncline={() => setDpr(1.5)} onDecline={() => setDpr(1)} ></PerformanceMonitor>
  //                 {useRing && <Rig />}
  //                 <Suspense fallback={<Loader></Loader>}>
  //                     {/* <THREE.CameraHelper></THREE.CameraHelper> */}
  //                     <Environment preset={night ? 'night' : 'apartment'} />
  //                     {/* <PointerLockControls></PointerLockControls> */}

  //                     {/* <ambientLight ref={rectAreaLightRef} args={[color, intensity, width, height]} position={[0, 5, 0]} rotation-x={-Math.PI / 2} /> */}
  //                     <ambientLight intensity={night ? 0 : 0.2} />
  //                     <hemisphereLight
  //                         color="#010101"
  //                         skyColor="#fff"
  //                         groundColor="#fff"
  //                         intensity={night ? 0 : 3}
  //                         position={[0, 20, 0]}
  //                     />
  //                     <directionalLight
  //                         // color="0xfcfcfc"
  //                         color="#fce192"
  //                         castShadow={true}
  //                         position={[18, 12, 5]}
  //                         angel={30}
  //                         intensity={night ? 0 : 4}
  //                         // intensity={0.1}
  //                         shadow-mapSize={1024}
  //                     // shadow-radius={1}
  //                     // shadow-mapSize={[524, 524]}
  //                     >
  //                         <orthographicCamera attach="shadow-camera" args={[-15, 15, 15, -15, 2, 500]} />
  //                     </directionalLight>

  //                     {/* <pointLight position={[0, -10, 0]} intensity={1} /> */}
  //                     {useContactShadow && <ContactShadows position={[0, 0, 0]} opacity={0.75} scale={40} blur={1} far={10} />}
  //                     <group
  //                         position={[0,0.1,2]}
  //                         rotation={[0, -0.785, 0]}>
  //                         {night &&
  //                             <group position={[0, 0, 0]}>
  //                                 <pointLight
  //                                     castShadow
  //                                     position={[4.9, 0.9, 10]}
  //                                     color="white"
  //                                     args={["#FFCF48", 0.5, 2, 5]}
  //                                 />
  //                                 <pointLight
  //                                     castShadow
  //                                     position={[3.9, 0.9, 10]}
  //                                     color="white"
  //                                     args={["#FFCF48", 0.5, 2, 4]}
  //                                 />
  //                                 <pointLight
  //                                     castShadow
  //                                     position={[3.9, 0.9, 9.4]}
  //                                     color="white"
  //                                     args={["#FFCF48", 1, 3, 5]}
  //                                 />
  //                                 <pointLight
  //                                     castShadow
  //                                     position={[9.9, 0.5, -3.1]}
  //                                     color="white"
  //                                     args={["#FFCF48", 0.7, 5, 1.5]}
  //                                 ></pointLight>
  //                                 <pointLight
  //                                     castShadow
  //                                     position={[11, 0.5, -3.1]}
  //                                     color="white"
  //                                     args={["#FFCF48", 0.7, 5, 1.5]}
  //                                 ></pointLight>
  //                                 <pointLight
  //                                     castShadow
  //                                     position={[9.9, 0.5, -4.2]}
  //                                     color="white"
  //                                     args={["#FFCF48", 0.7, 5, 1.5]}
  //                                 ></pointLight>
  //                                 <pointLight
  //                                     castShadow
  //                                     position={[11, 0.5, -4.5]}
  //                                     color="white"
  //                                     args={["#FFCF48", 0.7, 5, 1.5]}
  //                                 ></pointLight>
  //                                 {/* <spotLight
  //                                     castShadow
  //                                     position={[4.5, 2.4, 7.25]}
  //                                     rotation={[1,0,0]}
  //                                     power={15}
  //                                     angle={-1}
  //                                     color="white"
  //                                     // args={["#FFCF48", 1, 5, 5]}
  //                                 >
  //                                     <mesh>
  //                                         <sphereGeometry args={[0.05, 5, 5]} />
  //                                         <meshLambertMaterial color="green" roughness={0} metalness={0.1} />
  //                                     </mesh>
  //                                 </spotLight> */}
  //                             </group>
  //                         }
  //                         <Map
  //                             position={[0, 0, 0]}
  //                             scale={0.2}
  //                         />
  //                         <House
  //                             castShadow
  //                             position={[7.2, 0.19, 10.4]}
  //                             rotation={[0, -1.55, 0]}
  //                             scale={0.35}
  //                         // rotation={houseRotation}
  //                         />
  //                         <Med
  //                             position={[4, 0.11, -.5]}
  //                             scale={0.18}
  //                         />
  //                         <Farm
  //                             position={[7, 0.11, -6.5]}
  //                             scale={0.4}
  //                         />
  //                         {/* <RedSocks
  //                             position={[0, 0.25, 13]}
  //                             scale={0.45}
  //                         /> */}
  //                         <COD
  //                             position={[2.3, 0.12, 10.9]}
  //                             scale={0.15}
  //                         />
  //                         <CEX
  //                             position={[-9.5, 0.09, -3.8]}
  //                             rotation={[0, -1.575, 0]}
  //                             scale={0.3}
  //                         />
  //                         <PishProizvodstvo
  //                             rotation={[0, -1.57, 0]}
  //                             position={[-8.565, 0.11, -9.2]}
  //                             scale={0.38}
  //                         />
  //                         <Stadion
  //                             position={[4, 0.1, 3.5]}
  //                             rotation={[0, 1.548, 0]}
  //                             scale={0.7}
  //                         />
  //                     </group>

  //                     {/* <Planep
  //                         position={[0, 0, 0]}
  //                         scale={planeScale}
  //                     ></Planep> */}

  //                     {/* <Road
  //                         position={roadPosition}
  //                         scale={roadScale}
  //                         rotation={roadRotation}
  //                     /> */}

  //                     {/* <Boath/> */}
  //                     <Bird />
  //                     <OrbitControls
  //                         mouseButtons={{
  //                             LEFT:  THREE.MOUSE.ROTATE,
  //                             RIGHT: THREE.MOUSE.ROTATE
  //                         }}
  //                         // maxAzimuthAngle={1.9}
  //                         // setAzimuthalAngle={1}
  //                         // minAzimuthAngle={0.9}
  //                         maxAzimuthAngle={1}
  //                         setAzimuthalAngle={-1}
  //                         minAzimuthAngle={-1.5}
  //                         maxDistance={26}
  //                         minDistance={26}
  //                         maxPolarAngle={1}
  //                         minPolarAngle={.7}
  //                         // zoom0={60}
  //                         maxZoom={useOrthographicCamera ? 40 : 4}
  //                         minZoom={useOrthographicCamera ? 40 : 4}
  //                         zoomToCursor
  //                     />
  //                     {/* <OrthographicCamera
  //                         makeDefault
  //                         zoom={4}
  //                         bottom={-200}
  //                         left={200}
  //                         right={-200}
  //                         near={1}
  //                         far={50000}
  //                         position={[0, 0, 0]}
  //                     /> */}
  //                     {/* <axesHelper args={[500]} /> */}
  //                 </Suspense>
  //             </Canvas>
  //         </section>
  //     </div>
  // )
};
export default App;
