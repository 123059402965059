import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from '@react-spring/three'
import * as THREE from 'three'
import CEXScene from '../accests/3d/doroga_n.glb'
import gsap from "gsap";

const Doroga_n = (props) => {
  const { nodes, materials } = useGLTF(CEXScene);
  const [hovered, hover] = useState(false)
  // materials["Material.019"].metalness = 0.4

  const colorChange = async () => {
    let key = Object.keys(materials)
    // //console.log(key)
    if (props.fog)
      key.forEach(element => {
        if (element != 'окна' && element != 'выступы')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5
          });
      });
    else {
      key.forEach(element => {
        if (element != 'окна' && element != 'выступы')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0
          });
      });
    }
  }
  useEffect(() => {
    colorChange()
  }, [props.fog])

  const cexRef = useRef()
  return (
    <a.group {...props} ref={cexRef}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.doroga.geometry}
        material={materials.Doroga}
        position={[18.388, 0.377, 7.619]}
        scale={1.934}
      />
    </a.group>
  );
}

export default Doroga_n;