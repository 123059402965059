import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useHttp } from '../hooks/http.hook'

import { AlarmTwoTone } from '@mui/icons-material';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


export default function CompanyTableComponent(props) {
  const [categoryRows, setCategoryRows] = useState(null)
  const { loading, request, error, clearError } = useHttp()


  const getCategory = async () => {
    const datat = await request('/api/reference', 'GET'
    )
    if (datat) {
      let bufRows = []
      for (let row of datat) {
        bufRows.push(row)
      }
      setCategoryRows(new Map(bufRows.map(item => [item._id, item])))

    }
  }

  React.useEffect(() => {
    // getCategory()
  }, [])


  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {props?.headers?.map((element, id) =>
              <TableCell key={id}>{element}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.data?.map((row, id) => (
            <TableRow
              onClick={() => props.selected(row)}
              // onClick={(e)=>{alert(e)}}
              hover
              key={id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
            >
              <TableCell component="th" scope="row" >
                {row.name ?? ''}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.text ?? ''}
              </TableCell>
              <TableCell component="th" scope="row">
                <div dangerouslySetInnerHTML={{ __html: row.description ?? '' }}></div>
              </TableCell>
              <TableCell component="th" scope="row">
                <img style={{width:"150px"}} src={row.img ?? ''}></img>
                {/* {//console.log("sadasaaaa",)} */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}