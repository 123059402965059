import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as Chevron } from "../accests/chevron.svg";
import { CollapsibleComponents } from "./CollapsibleComponents";

import InnerHTML from "dangerously-set-html-content";
import axios from "axios";

export default function RMenuOborudovanie(props) {
  const [leftMenuW, setLeftMenuW] = useState("20rem");
  const [iconMenu, setIconMenu] = useState("->");
  const [itemOpen, setItemOpen] = useState(-1);
  const [more, setMore] = useState(-1);
  const [gallery, setGallery] = useState(false);
  const [category, setCategory] = useState(false);
  const myRef = useRef(null);
  const [navigationMenuVisibleState, setNavigationMenuVisibleState] =
    React.useState(false);
  // const executeScroll = () => myRef.current.scrollIntoView();
  const [refs, setResf] = useState([]);
  const handleClick = (id) => {
    if (refs.length > 0) {
      //console.log("select", refs[id]);
      refs[id].current.scrollIntoView({
        behavior: "smooth",
        // block: "start",
      });
    }
  };
  const handleCollapseLeftMenu = () => {
    setNavigationMenuVisibleState(!navigationMenuVisibleState);
    // if (leftMenuW == '20rem') {
    //     setLeftMenuW('0')
    // if (navigationMenuVisibleState)
    //     setIconMenu('->')
    // else{
    //     setIconMenu('<-')
    // }
    // } else {
    //     setLeftMenuW('20rem')
    //     setIconMenu('<-')s
    // }
  };
  useEffect(() => {
    if ("data" in props) {
      let buf = [];
      if (props.data)
        if (props.data.data) {
          props.data.data.map((acc, value) => {
            //console.log("refsss");
            acc[value.id] = React.createRef();
            buf.push(acc);
          });
          setResf(buf);
        }
    }
    //console.log("asdasd", props.selectMenu);
  }, []);
  useEffect(() => {
    //console.log("open", props.open);
    if (props.open - 1 != -1) {
      setItemOpen(props.open - 1);
      setMore(-1);
      //console.log("asd");
      // handleClick(props.open - 1);
    }
  }, [props.open]);

  const toggleGallery = (id) => {
    props.selectGallery(id);
    setGallery(!gallery);
  };

  const toggleCategory = () => {
    setCategory(!category);
  };

  // useEffect(() => {
  //   let buf = [];
  //   if ("data" in props) {
  //     if (props.data)
  //       if (props.data.data) {
  //         props.data.data.map((acc, value) => {
  //           //console.log("refsss");
  //           acc[value.id] = React.createRef();
  //           buf.push(acc);
  //         });
  //         setResf(buf);
  //       }
  //   }
  // }, [props.data]);
  return (
    <div
      className="RMenu"
      style={{
        background: "transparent",
        backgroundColor: "transparent",
        boxShadow: "none",
      }}
    >
      <div ref={myRef} style={{ minWidth: "330px" }}>
        {/* <div
          onClick={() => {
            //console.log("props.categoryData", props.categoryData)
            let main = props.categoryData
            main['main'] = true
            props.setAloneItem(main)
            // if (itemOpen === 0) {
            //   props.selectGallery(null);
            //   setItemOpen(-1);
            //   props.setSelectObject(-1);
            //   setMore(-1);
            // } else {
            //   setItemOpen(0);
            //   props.selectGallery(props.categoryData);
            //   setMore(-1);
            // }
          }}
          className="elementTitle"
          style={{
            position: "relative",
            minHeight: "60px",
            padding: "20px",
            margin: "0 0 10px auto",
            width: "300px",
            fontSize: "32px",
            fontWeight: "700",
            lineHeight: "normal",
            borderTopLeftRadius: "50px",
            borderBottomRightRadius: "50px",
            boxShadow: "4px 4px 8px 0 rgba(34,60,80,.2)",
            backgroundColor: "rgba(5, 112, 184, 0.8)",
            listStyle: "none",
            cursor: "pointer",
          }}
        >
          <div>
            <h3 style={{ color: "white" }}>{props.categoryData?.name}</h3>
          </div>
        </div> */}
        <div
          className="elementTitle"
          // onClick={toggle}
          onClick={() => {
            if (itemOpen === -2) {
              setItemOpen(-1);
              props.setSelectObject(-1);
              props.setAloneItem(null);
              setMore(-1);
            } else {
              setItemOpen(-2);
              setMore(-1);
            }
          }}
          style={{
            position: "relative",
            minHeight: "60px",
            padding: "20px",
            margin: "0 0 10px auto",
            width: "300px",
            fontSize: "32px",
            fontWeight: "700",
            lineHeight: "normal",
            borderTopLeftRadius: "50px",
            borderBottomRightRadius: "50px",
            boxShadow: "4px 4px 8px 0 rgba(34,60,80,.2)",
            backgroundColor: "rgba(5, 112, 184, 0.8)",
            listStyle: "none",
            cursor: "pointer",
          }}
        >
          <div>
            <h3 style={{ color: "white" }}>{props.categoryData?.name}</h3>
          </div>
          {-2 === itemOpen && (
            <div className="toggleTitle">
              <p>
                {props.categoryData?.text ?? ""}
              </p>
              <br></br>
              {props.categoryData?.description && (
                <a
                  style={{ textDecoration: "underline" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    let main = props.categoryData;
                    main["main"] = true;
                    props.setAloneItem(main);
                    // props.setAloneItem(element);

                    // if (more == id) {
                    //   setMore(-1);
                    // } else setMore(id);
                  }}
                >
                  Подробнее
                </a>
              )}
            </div>
          )}
          {/* {more == id && (
            <div className="toggleMore">
              <p>{element?.description ?? ""}</p>
            </div>
          )} */}
        </div>

        {props.data &&
          props.data.data.length > 0 &&
          props.data.data.map(
            (element, id) => {
              return (
                <div
                  ref={refs[element.id]}
                  className={itemOpen === id ? "elementSelect" : "element"}
                  // onClick={toggle}
                  onClick={() => {
                    if (itemOpen === id) {
                      props.setAloneItem(null);
                      props.selectGallery(null);
                      setItemOpen(-1);
                      props.setSelectObject(-1);
                      setMore(-1);
                    } else {
                      props.setAloneItem(null);
                      props.selectGallery(element);
                      setItemOpen(id);
                      setMore(-1);
                    }
                  }}
                  style={{
                    position: "relative",
                    minHeight: "60px",
                    // height:'272px',
                    padding: "20px",
                    margin: "0 0 10px auto",
                    width: 0 ? "800px" : "300px",
                    borderTopLeftRadius: "50px",
                    borderBottomRightRadius: "50px",
                    boxShadow: "4px 4px 8px 0 rgba(34,60,80,.2)",
                    color: "black",

                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    listStyle: "none",
                    cursor: "pointer",
                  }}
                >
                  <div>
                    <h3 style={{ color: "black" }}>{element.name ?? ""}</h3>
                  </div>
                </div>
              );
              // if (id != more) {
              //   return (
              //     <div
              //       ref={refs[element.id]}
              //       className="element"
              //       // onClick={toggle}
              //       onClick={() => {
              //         if (itemOpen === id) {
              //           setItemOpen(-1);
              //           props.setSelectObject(-1);
              //           setMore(-1);
              //         } else {
              //           setItemOpen(id);
              //           setMore(-1);
              //         }
              //       }}
              //       style={{
              //         position: "relative",
              //         minHeight: "60px",
              //         // height:'272px',
              //         padding: "20px",
              //         margin: "0 0 10px auto",
              //         width: 0 ? "800px" : "300px",
              //         borderTopLeftRadius: "50px",
              //         borderBottomRightRadius: "50px",
              //         boxShadow: "4px 4px 8px 0 rgba(34,60,80,.2)",
              //         color: "black",

              //         backgroundColor: "rgba(255, 255, 255, 0.8)",
              //         listStyle: "none",
              //         cursor: "pointer",
              //       }}
              //     >
              //       <div>
              //         <h3 style={{ color: "black" }}>{element.title ?? ""}</h3>
              //       </div>
              //       {id === itemOpen && (
              //         <div className="toggle">
              //           <p>{element?.text ?? ""}</p>
              //           <br></br>
              //           {element?.more && (
              //             <a
              //               style={{ textDecoration: "underline" }}
              //               onClick={(e) => {
              //                 e.stopPropagation();
              //                 if (more == id) {
              //                   setMore(-1);
              //                 } else setMore(id);
              //               }}
              //             >
              //               Подробнее
              //             </a>
              //           )}
              //         </div>
              //       )}
              //       {more == id && (
              //         <div className="toggleMore">
              //           <p>{element?.more ?? ""}</p>
              //         </div>
              //       )}
              //     </div>
              //   );
              // } else {
              //   return (
              //     <div
              //       style={{
              //         position: "relative",
              //         minHeight: "300px",
              //         // padding: '20px',
              //         margin: "0 0 10px auto",
              //         width: "70vw",
              //         maxHeight: "600px",
              //         borderTopLeftRadius: "50px",
              //         color: id % 2 == 0 ? "black" : "white",
              //         borderBottomRightRadius: "50px",
              //         boxShadow: "4px 4px 8px 0 rgba(34,60,80,.2)",
              //         backgroundColor: "rgba(255, 255, 255, 0.8)",
              //         listStyle: "none",
              //         cursor: "pointer",
              //       }}
              //     >
              //       <div
              //         onClick={() => {
              //           if (itemOpen === id) {
              //             setItemOpen(-1);
              //             props.setSelectObject(-1);
              //             setMore(-1);
              //           } else {
              //             setItemOpen(id);
              //             setMore(-1);
              //           }
              //         }}
              //       >
              //         <h3
              //           // onClick={}
              //           style={{
              //             color: "black",
              //             position: "absolute",
              //             left: "2em",
              //             top: "15px",
              //             background: "#FFFFFF66",
              //             borderRadius: "10px",
              //             padding: "5px",
              //           }}
              //         >
              //           {element?.title ?? ""}
              //         </h3>
              //       </div>
              //       <div className="toggleMore">
              //         <div
              //           className="togglePhoto"
              //           onClick={() => {
              //             if (itemOpen === id) {
              //               setItemOpen(-1);
              //               props.setSelectObject(-1);
              //               setMore(-1);
              //             } else {
              //               setItemOpen(id);
              //               setMore(-1);
              //             }
              //           }}
              //           // onClick={toggle}
              //           style={{
              //             height: "200px",
              //             borderTopLeftRadius: "50px",
              //             backgroundImage: `url("${element?.photo}")`,
              //             backgroundSize: "100%",
              //           }}
              //         ></div>
              //         {/* <div
              //           className="toggleMoreText"
              //           style={{
              //             padding: "20px 30px",
              //             overflow: "auto",
              //             maxHeight: "400px",
              //           }}
              //         >
              //           <InnerHTML html={element?.more}></InnerHTML>
              //         </div> */}
              //       </div>
              //     </div>
              //   );
              // }
            }

            // <CollapsibleComponents
            //   id={id}
            //   selectGallery={0}
            //   selectMenu={1}
            //   setOpenItem = {setItemOpen}
            //   open={itemOpen===id? '1':'0'}
            //   // open={props.open!=null && props.open!=8 && element.open-1===id? '1':'0'}
            //   element={element}
            // ></CollapsibleComponents>
          )}
        {/* {props.data == null && <h4>Записей еще нет</h4>} */}
      </div>
    </div>
  );
}
