import React, { Fragment } from 'react'

import { Routes, Route, Redirect, Router } from 'react-router-dom'
import { Main } from './pages/Main'
import { AuthPage } from './pages/AuthPage'
import { AdminPage } from './pages/AdminPage'

export const useRoutes = isAuthenticated => {
    // if (isAuthenticated) {
        if (1) {
        return (
            <Routes>
                <Route path="/" element={<Main />}></Route>
                {/* <Route path="/admin" element={<AdminPage />}></Route> */}
                <Route path="/admin" element={<AdminPage />}></Route>
                {/* <Route path="" element={<AdminPage />}></Route> */}
                <Route path="*" element={<Main />}></Route>
            </Routes>
        )
    }

    return (
        <Routes>
            <Route path="/" element={<Main />}></Route>
            <Route path="/enter" element={<AuthPage />}></Route>
            <Route path="/admin" element={<AuthPage />}></Route>
            <Route path="*" element={<Main />}></Route>
        </Routes>

    )
}