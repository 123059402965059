import { useState, useCallback, useContext } from 'react'
import { AuthContext } from '../context/AuthContext'
import { useAuth } from '../hooks/auth.hook'
export const useHttp = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const { logout} = useAuth()

    const request = useCallback(async (url, method = 'GET', body = null, headers = {}) => {
        setLoading(true)
 
        try {
            if (body) {
                body = JSON.stringify(body)
                headers['Content-Type'] = 'application/json'
            }

            const response = await fetch(url, { method, body, headers })
            
            if(response.status == 404 || response.status == 500){
                logout()
                throw new Error('Что-то пошло не так')
            }
            let data = ''
            try{
                 data = await response.json()
            }
            catch(e){
                 data = null
            }
            if (!response.ok) {
                throw new Error(data.msg || data.message || 'Что-то пошло не так')
            }

            setLoading(false)

            return data
        } catch (e) {
            setLoading(false)
            setError(e.message)
            throw e
        }
    }, [])

    const clearError = useCallback(() => setError(null), [])

    return { loading, request, error, clearError }
}