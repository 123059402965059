import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from '@react-spring/three'
import gsap from "gsap";
import medScene from '../accests/3d/поликлиника.glb'

import * as THREE from 'three'
THREE.ColorManagement.legacyMode = false;
const Med = (props) => {
  const { nodes, materials } = useGLTF(medScene);
  const [hovered, hover] = useState(false)
  materials.окна.emissive = new THREE.Color(0.9, 0.9, 0.9)
  materials.крест.emissive = new THREE.Color(0.9, 0, 0)
  materials.здание.tonemapped=false
  const medRef = useRef()
  const colorChange = async () => {
    let key = Object.keys(materials)
    //console.log(key)
    if (hovered)
      key.forEach(element => {
        if (element != 'окна' && element != 'крест')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5
          });
        // materials[element].emissive = new THREE.Color(0.2, 0.2, 0.2)
      });
    else {
      key.forEach(element => {
        materials[element].tonemapped=false
        if (element != 'окна' && element != 'крест')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0
          });
        // materials[element].emissive = new THREE.Color(0, 0, 0)
      });
    }
  }
  useEffect(() => {
    colorChange()
  }, [hovered])
  return (
    <a.group {...props} ref={medRef}
      ton
      onPointerOver={(event) => {
        event.stopPropagation()
        hover(true)
      }
      }
      onPointerOut={(event) => {
        hover(false)
      }}
      
    >
      <group position={[6.184, 1.452, -7.679]} scale={[2.784, 4.478, 2.784]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube002_1.geometry}
          material={materials.здание}
        >
          </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube002_2.geometry}
          material={materials.крест}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube002_3.geometry}
          material={materials.арка}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube002_4.geometry}
          material={materials.шахта}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube020.geometry}
        material={materials.шахта}
        position={[-1.22, 6.059, 3.779]}
        scale={[1.295, 4.039, 1.494]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004.geometry}
        material={materials.шахта}
        position={[0.669, 6.486, 3.758]}
        scale={[0.73, 14.436, 1.397]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002.geometry}
        material={materials.шахта}
        position={[1.926, 8.281, 3.761]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.73, 11.475, 1.397]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube003.geometry}
        material={materials.шахта}
        position={[3.736, 6.472, 0.583]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={[0.73, 14.436, 1.397]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube005.geometry}
        material={materials.шахта}
        position={[3.736, 8.267, 1.84]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={[0.73, 11.475, 1.397]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube006.geometry}
        material={materials.шахта}
        position={[2.17, 1.311, 3.909]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.73, 11.963, 1.397]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube007.geometry}
        material={materials.шахта}
        position={[3.906, 1.311, 2.018]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={[0.73, 12.898, 1.397]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube008.geometry}
        material={materials.шахта}
        position={[3.611, 0.746, 3.905]}
        rotation={[0, 0, -Math.PI]}
        scale={[0.73, 4.747, 1.397]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube009.geometry}
        material={materials.шахта}
        position={[3.905, 0.746, 3.614]}
        rotation={[Math.PI, -1.571, 0]}
        scale={[0.73, 4.747, 1.397]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.окна.geometry}
        material={materials.окна}
        position={[6.184, 1.452, -7.679]}
        scale={[2.784, 4.478, 2.784]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text.geometry}
        material={materials.крест}
        position={[7.138, 3.008, -6.306]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
    </a.group>
  );
}

export default Med;