import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from "@react-spring/three";
import * as THREE from "three";
import ModelScene from "../accests/3d/fontan_n.glb";
import gsap from "gsap";

const Fontan_n = (props) => {
  const { nodes, materials } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false);

  const colorChange = async () => {
    let key = Object.keys(materials);
    if (props.fog && !hovered)
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5,
          });
      });
    else {
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0,
          });
      });
    }
  };
  useEffect(() => {
    colorChange();
  }, [props.fog]);
  const modelRef = useRef();
  return (
    <a.group
      {...props}
      ref={modelRef}
       
    >
      <group scale={3.454}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle_1.geometry}
          material={materials.фонтан}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle_2.geometry}
          material={materials["фонтан.001"]}
        />
      </group>
    </a.group>
  );
};

export default Fontan_n;
