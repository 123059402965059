import React, { useEffect, useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import pdfSVG from '../accests/pdf.svg'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { AuthContext } from '../context/AuthContext'
import { useHttp } from '../hooks/http.hook'
import Dropdown from 'react-dropdown';
import { styled } from '@mui/material/styles';

import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import 'react-dropdown/style.css';
import DraftEditor from './Editor/DraftEditor';

import ReactQuill from 'react-quill';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Stack } from '@mui/material';
function preventDefault(event) {
    event.preventDefault();
}


const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        ['link'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
        [{ 'image': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ]
}
const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
]
export default function SolutionsCreateComponents(props) {

    const { token, logout } = useContext(AuthContext)
    const [title, setTitle] = useState(null)
    const [text, setText] = useState('')
    const [more, setMore] = useState(null)
    const [preview, setPreview] = useState('')
    const [photo, setPhoto] = useState('')
    const [reload, setReload] = useState(null)
    const [postRows, setPostRows] = useState(new Map())
    const [optionsPost, setOptionsPost] = useState([])
    const [valueDropDownPost, setValueDropDownPost] = useState(null)
    const [documentRows, setDocumentRows] = useState(new Map())
    const [optionsDocument, setOptionsDocument] = useState([])
    const [valueDropDownDocument, setValueDropDownDocument] = useState(null)

    const { loading, request, error, clearError } = useHttp()

    const uploadImage = async (file) => {
        const fmData = new FormData();
        const config = {
            headers: { "content-type": "multipart/form-data", "authorization": `Bearer ${token}` },
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);

            }
        };
        fmData.append("file", file.target.files[0]);
        try {
            const res = await axios.post(
                "/api/files/download",
                fmData,
                config
            );

            // onSuccess("Ok");
            setPhoto(res.data.filename)
        } catch (err) {
            setPhoto('')
            console.log("Eroor: ", err);
            const error = new Error("Some error");
            // onError({ err });
        }
    };


    const getPost = async () => {
        const datat = await request('/api/equipments_posts/', 'GET', null, {
            authorization: `${token}`
        }
        )
        if (datat) {
            let bufRows = []
            let bufRows2 = []
            //console.log(datat)
            for (let row of datat) {
                bufRows.push({ 'label': row.name, 'value': row.id })
                bufRows2.push({ 'name': row.name, 'id': row.id })
            }
            setPostRows(new Map(bufRows2.map(item => [item.id, item.name])))
            // console.log("new Map(bufRows2.map(item => [item.id, item.name]))", new Map(bufRows2.map(item => [item.id, item.name])))
            setOptionsPost(bufRows)
            // setValueDropDownPost({ value: props.select.equipments_posts_id, label: postRows.get(props.select.equipments_posts_id) })

        }
    }

    const getDocument = async () => {
        const datat = await request('/api/documents/', 'GET', null, {
            authorization: `${token}`
        }
        )
        if (datat) {
            let bufRows = []
            let bufRows2 = []
            //console.log(datat)
            for (let row of datat) {
                bufRows.push({ 'label': row.name, 'value': row.id })
                bufRows2.push({ 'name': row.name, 'id': row.id })
            }
            setDocumentRows(new Map(bufRows2.map(item => [item.id, item.name])))
            // console.log("new Map(bufRows2.map(item => [item.id, item.name]))", new Map(bufRows2.map(item => [item.id, item.name])))
            setOptionsDocument(bufRows)
            // setValueDropDownDocument({ value: props.select.documents_id, label: documentRows.get(props.select.documents_id) })

        }
    }

    React.useEffect(() => {
        if (props?.select) {
            //console.log(props?.select)

            setTitle(props?.select?.name ?? '')
            setText(props?.select?.text ?? '')
            setPhoto(props?.select?.img ?? '')
            setMore(props?.select?.description ?? '')

        }

    }, [props])

    React.useEffect(() => {

        // getDocument()
        // getPost()
        // getMaking()
    }, [])

    React.useEffect(() => {
        if (reload) {
            props.update()
            setTitle('')
            setText('')
            setMore('')
            setPhoto('')
            setReload(null)

        }
    }, [reload])

    // useEffect(() => {
    //     console.log("select", valueDropDown)
    // }, [valueDropDown])

    const updateRow = async () => {
        if (props?.select && (props?.select?.name != title || props?.select?.text != text || props?.select?.description != more || props?.select?.img != photo)) {
            let data
            if (more) {
                data = await request(`/api/solutions_posts/${props.select.id}`, 'PUT', { name: title, text: text, description: more, img: photo }, {
                    authorization: `${token}`
                })
            } else {
                data = await request(`/api/solutions_posts/${props.select.id}`, 'PUT', { name: title, text: text, description: more, img: photo }, {
                    authorization: `${token}`
                })
            }
            if (data) {
                setReload(1)
            }
        }

    }

    const createRow = async () => {
        if (title) {
            const data = await request(`/api/solutions_posts/`, 'POST', { name: title, text: text, description: more, img: photo }, {
                authorization: `${token}`
            })
            if (data) {
                setReload(1)
            }
        }

    }
    const deleteRow = async () => {
        if (props.select) {
            const data = await request(`/api/solutions_posts/${props.select.id}`, 'DELETE', null, {
                authorization: `${token}`
            })
            setReload(1)
        }

    }
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    // useEffect(()=>{
    //     //console.log((props?.select?.text!=text && props?.select?.name!=title))
    // },[text, title])

    return (
        <React.Fragment>
            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="TextNameField"
                        // label={text?"Текст": ""}
                        variant="standard"
                        placeholder='Заголовок'
                        // translate='yes'
                        // disabled={props?.select ? "" : "1"}
                        value={title}
                        onChange={(e) => { setTitle(e.target.value) }} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="TextTextField"
                        // label={text?"Текст": ""}
                        variant="standard"
                        placeholder='Текс'
                        // translate='yes'
                        // disabled={props?.select ? "" : "1"}
                        value={text}
                        onChange={(e) => { setText(e.target.value) }} />
                </Grid>
                <Grid item xs={12}>
                    <ReactQuill theme="snow" modules={modules} formats={formats} value={more} onChange={setMore} />
                </Grid>
                <Grid item xs={12}>
                    {photo != '' &&
                        <Stack direction="row" spacing={2}>
                            <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => setPhoto('')}>
                                Удалить
                            </Button>
                            <img style={{width:"150px"}} src={photo ?? ''}></img>
                        </Stack>
                    }
                    {photo == '' &&
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                        >
                            Загрузить изображение
                            <VisuallyHiddenInput type="file" onChange={uploadImage} accept=".png" />
                        </Button>
                    }

                </Grid>
                <Grid item xs={5}>
                    <Button variant="contained"
                        onClick={updateRow}
                        disabled={(props?.select && (props?.select?.path != text || props?.select?.name != title)) ? "" : "1"}>Редактировать</Button>
                </Grid>
                <Grid item xs={5}>
                    <Button variant="contained"

                        onClick={createRow}
                    >Добавить</Button>
                </Grid>
                {/* <Grid item xs={2}>
                    <Button variant="contained"
                        color="error"
                        onClick={deleteRow}
                        disabled={(props?.select) ? "" : "1"}
                    ><DeleteForeverIcon></DeleteForeverIcon></Button>
                </Grid> */}
            </Grid>

        </React.Fragment>
    );
}