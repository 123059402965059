import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useHttp } from '../hooks/http.hook'

import { AlarmTwoTone } from '@mui/icons-material';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


export default function ReferencePostTableComponents(props) {
  const [categoryRows, setCategoryRows] = useState(null)
  const { loading, request, error, clearError } = useHttp()


  const getCategory = async () => {
    const datat = await request('/api/references_groups/', 'GET'
    )
    if (datat) {
      let bufRows = []
      for (let row of datat) {
        bufRows.push(row)
      }
      setCategoryRows(new Map(bufRows.map(item => [item.id, item])))
      const t = new Map(bufRows.map(item => [item.id, item]))
      // //console.log(t.get('65137035aec7f74e191150fc').title)

    }
  }

  React.useEffect(() => {
    getCategory()
  }, [])


  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {props?.headers?.map((element, id) =>
              <TableCell key={id}>{element}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.data?.map((row, id) => (
            <TableRow
              onClick={() => props.selected(row)}
              // onClick={(e)=>{alert(e)}}
              hover
              key={id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
            >
              <TableCell component="th" scope="row" >
                {row.name ?? ''}
              </TableCell>
              <TableCell component="th" scope="row" >
                <div dangerouslySetInnerHTML={{ __html: row.text ?? '' }}></div>
              </TableCell>
              <TableCell component="th" scope="row" >
                <img style={{width:"150px"}} src={row.img ?? ''}></img>
              </TableCell>
              <TableCell component="th" scope="row">
                {categoryRows ? categoryRows.get(row.references_groups_id).name : ''}
              </TableCell>
              {/* <TableCell component="th" scope="row">
                {categoryRows? categoryRows.get(row.categori).title:  ''}
                {//console.log("sadasaaaa",)}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.photo ?? ''}
                {//console.log("sadasaaaa",)}
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}