import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from '@react-spring/three'
import * as THREE from 'three'
import ModelScene from '../accests/3d/fabrika_n2.glb'
import gsap from "gsap";

const Fabrika_n2 = (props) => {
  const { nodes, materials } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false)

  const colorChange = async () => {
    let key = Object.keys(materials)
    if (props.fog && !hovered)
      key.forEach(element => {
        if (element != 'окна' && element != 'выступы')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5
          });
      });
    else {
      key.forEach(element => {
        if (element != 'окна' && element != 'выступы')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0
          });
      });
    }
  }
  useEffect(() => {
    colorChange()
  }, [props.fog])
  const modelRef = useRef()
  return (
    <a.group {...props} ref={modelRef}
      onPointerOver={(event) => {
        event.stopPropagation()
        hover(true)
        props.setFog(true)
      }
      }
      onPointerOut={(event) => {
        hover(false)
        props.setFog(false)
      }}
      onClick={()=>{
        props.select(5)
      }}
    >
       <group position={[-64.697, 0, 21.102]} scale={2.138}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.вентили001.geometry}
          material={materials.вентили}
          position={[30.259, 0, -9.87]}
          scale={0.024}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.вентили002.geometry}
          material={materials.вентили}
          position={[33.533, 0, -9.87]}
          scale={0.024}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.вентили003.geometry}
          material={materials.вентили}
          position={[33.533, 0, -8.898]}
          scale={0.024}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.вентили004.geometry}
          material={materials.вентили}
          position={[33.533, 0, -7.901]}
          scale={0.024}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.вентили005.geometry}
          material={materials.вентили}
          position={[30.284, 0, -7.901]}
          scale={0.024}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.вентили006.geometry}
          material={materials.вентили}
          position={[30.284, 0, -8.924]}
          scale={0.024}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.выступы.geometry}
          material={materials.выступ}
          position={[30.262, 0, -9.87]}
          scale={[-0.074, -0.208, -0.301]}
        />
        <group position={[30.262, 0, -9.87]} rotation={[0, 1.396, 0]} scale={0.033}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle042.geometry}
            material={materials.листва}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle042_1.geometry}
            material={materials.ствол}
          />
        </group>
        <group position={[30.262, 0, -9.87]} scale={[1.883, 1.839, 2.83]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube039.geometry}
            material={materials['здание.005']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube039_1.geometry}
            material={materials['трубы.001']}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.окна.geometry}
          material={materials['окна.003']}
          position={[30.262, 0, -9.87]}
          scale={[1.883, 1.839, 2.83]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.основа.geometry}
          material={materials.основа}
          position={[30.262, 0, -9.87]}
          scale={[3.926, 3.926, 5.918]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.основа001.geometry}
          material={materials.листва}
          position={[30.262, 0, -9.87]}
          scale={[3.926, 3.926, 5.918]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.разметка002.geometry}
          material={materials['разметка.001']}
          position={[30.262, 0, -9.87]}
          rotation={[0, 0, -Math.PI]}
          scale={[-3.861, -1, -0.044]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.трава.geometry}
          material={materials.листва}
          position={[30.262, 0, -9.87]}
          rotation={[0, -1.571, 0]}
          scale={[3.379, 3.379, 5.094]}
        />
        <group position={[30.262, 0, -9.887]} scale={[0.4, 0.433, 0.4]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle001.geometry}
            material={materials['трубы.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle001_1.geometry}
            material={materials.выступы}
          />
        </group>
        <group position={[30.262, 0, -9.872]} scale={[0.4, 0.433, 0.4]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle002.geometry}
            material={materials['трубы.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle002_1.geometry}
            material={materials.выступы}
          />
        </group>
        <group position={[30.262, 0, -9.861]} scale={[0.4, 0.433, 0.4]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle003.geometry}
            material={materials['трубы.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle003_1.geometry}
            material={materials.выступы}
          />
        </group>
        <group position={[30.262, 0, -9.885]} scale={[0.4, 0.433, 0.4]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle004.geometry}
            material={materials['трубы.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle004_1.geometry}
            material={materials.выступы}
          />
        </group>
        <group position={[33.018, 0, -9.885]} scale={[0.4, 0.433, 0.4]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle004.geometry}
            material={materials['трубы.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle004_1.geometry}
            material={materials.выступы}
          />
        </group>
        <group position={[30.262, 0, -10.335]} scale={[0.4, 0.433, 0.4]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle003.geometry}
            material={materials['трубы.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle003_1.geometry}
            material={materials.выступы}
          />
        </group>
        <group position={[30.262, 0, -10.16]} scale={[0.4, 0.433, 0.4]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle002.geometry}
            material={materials['трубы.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle002_1.geometry}
            material={materials.выступы}
          />
        </group>
        <group position={[30.262, 0, -10.488]} scale={[0.4, 0.433, 0.4]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle002.geometry}
            material={materials['трубы.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle002_1.geometry}
            material={materials.выступы}
          />
        </group>
        <group position={[30.262, 0, -10.792]} scale={[0.4, 0.433, 0.4]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle002.geometry}
            material={materials['трубы.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle002_1.geometry}
            material={materials.выступы}
          />
        </group>
        <group position={[30.262, -0.912, -9.887]} scale={[0.4, 0.433, 0.4]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle001.geometry}
            material={materials['трубы.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle001_1.geometry}
            material={materials.выступы}
          />
        </group>
        <group position={[30.262, 0, -9.87]} scale={[1.883, 1.839, 2.83]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube040.geometry}
            material={materials.вентили}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube040_1.geometry}
            material={materials['окна.003']}
          />
        </group>
        <group position={[30.262, 0, -9.87]} scale={0.277}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube041.geometry}
            material={materials.вентили}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube041_1.geometry}
            material={materials['окна.003']}
          />
        </group>
      </group>
    </a.group>
  );
}

export default Fabrika_n2;