import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from "@react-spring/three";
import * as THREE from "three";
import ModelScene from "../accests/3d/derevo2_n.glb";
import gsap from "gsap";

const Derevo2_n = (props) => {
  const { nodes, materials } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false);

  const colorChange = async () => {
    let key = Object.keys(materials);
    if (props.fog && !hovered)
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5,
          });
      });
    else {
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0,
          });
      });
    }
  };
  useEffect(() => {
    colorChange();
  }, [props.fog]);
  const modelRef = useRef();
  return (
    <a.group
      {...props}
      ref={modelRef}
     
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle001.geometry}
        material={materials.окружение}
        position={[-15.914, 0, -14.179]}
        scale={0.224}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle002.geometry}
        material={materials.окружение}
        position={[5.15, 0, -49.31]}
        scale={0.224}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle003.geometry}
        material={materials.окружение}
        position={[0.763, 0, -56.689]}
        scale={0.224}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle004.geometry}
        material={materials.окружение}
        position={[-1.559, 0, -66.108]}
        scale={0.224}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle005.geometry}
        material={materials.окружение}
        position={[-12.139, 0, -60.044]}
        scale={0.224}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle006.geometry}
        material={materials.окружение}
        position={[-13.042, 0, -70.495]}
        scale={0.224}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle007.geometry}
        material={materials.окружение}
        position={[-7.107, 0, -76.043]}
        scale={0.224}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle008.geometry}
        material={materials.окружение}
        position={[-2.849, 0, -85.719]}
        scale={0.224}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle009.geometry}
        material={materials.окружение}
        position={[14.053, 0, -89.203]}
        scale={0.224}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle010.geometry}
        material={materials.окружение}
        position={[-73.813, 0, -82.236]}
        scale={0.224}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle011.geometry}
        material={materials.окружение}
        position={[-31.106, 0, -86.752]}
        scale={0.224}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle012.geometry}
        material={materials.окружение}
        position={[-40.911, 0, -86.752]}
        scale={0.224}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle013.geometry}
        material={materials.окружение}
        position={[-41.299, 0, -79.268]}
        scale={0.224}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle018.geometry}
        material={materials.окружение}
        scale={0.224}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle033.geometry}
        material={materials.окружение}
        position={[-9.218, 0, -9.936]}
        scale={0.224}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle037.geometry}
        material={materials.окружение}
        scale={0.224}
      />
    </a.group>
  );
};

export default Derevo2_n;
