import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from '@react-spring/three'
import * as THREE from 'three'
import ModelScene from '../accests/3d/stadion_n2.glb'
import gsap from "gsap";

const Stadion_n2 = (props) => {
  const { nodes, materials } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false)

  const colorChange = async () => {
    let key = Object.keys(materials)
    if (props.fog && !hovered)
      key.forEach(element => {
        if (element != 'окна' && element != 'выступы')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5
          });
      });
    else {
      key.forEach(element => {
        if (element != 'окна' && element != 'выступы')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0
          });
      });
    }
  }
  useEffect(() => {
    colorChange()
  }, [props.fog])
  const modelRef = useRef()
  return (
    <a.group {...props} ref={modelRef}
      onPointerOver={(event) => {
        event.stopPropagation()
        hover(true)
        props.setFog(true)
      }
      }
      onPointerOut={(event) => {
        hover(false)
        props.setFog(false)
      }}
      onClick={()=>{
        props.select(4)
      }}
    >
      <group position={[6.487, 0, -81.828]} scale={1.144}>
        <group
          position={[-5.672, 0, 71.553]}
          rotation={[-Math.PI, 1.566, -Math.PI]}
          scale={[9.484, 6.885, 13.193]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube010.geometry}
            material={materials.синий}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube010_1.geometry}
            material={materials.белый}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube010_2.geometry}
            material={materials.черный}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube010_3.geometry}
            material={materials.синий_темный}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube010_4.geometry}
            material={materials['черный.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube010_5.geometry}
            material={materials.ворота}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube010_6.geometry}
            material={materials.поле}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube010_7.geometry}
            material={materials.лед}
          />
        </group>
      </group>
    </a.group>
  );
}

export default Stadion_n2;