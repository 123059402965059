import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from '@react-spring/three'
import * as THREE from 'three'
import ModelScene from '../accests/3d/prom_n2.glb'
import gsap from "gsap";

const Prom_n2 = (props) => {
  const { nodes, materials } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false)

  const colorChange = async () => {
    let key = Object.keys(materials)
    if (props.fog && !hovered)
      key.forEach(element => {
        if (element != 'окна' && element != 'выступы')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5
          });
      });
    else {
      key.forEach(element => {
        if (element != 'окна' && element != 'выступы')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0
          });
      });
    }
  }
  useEffect(() => {
    colorChange()
  }, [props.fog])
  const modelRef = useRef()
  return (
    <a.group {...props} ref={modelRef}
      onPointerOver={(event) => {
        event.stopPropagation()
        hover(true)
        props.setFog(true)
      }
      }
      onPointerOut={(event) => {
        hover(false)
        props.setFog(false)
      }}
      onClick={()=>{
        props.select(3)
      }}
    >
        <group position={[-43.575, -0.097, -73.131]} rotation={[0, -1.57, 0]} scale={1.802}>
        <group position={[41.8, 0.054, -24.146]} scale={[1.979, 1.979, 4.72]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube020.geometry}
            material={materials['здание.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube020_1.geometry}
            material={materials['окна.001']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube020_2.geometry}
            material={materials.трубы}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube020_3.geometry}
            material={materials['здание.002']}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.освнование.geometry}
          material={materials['Material.008']}
          position={[41.8, 0.054, -24.146]}
          scale={[4.001, 0.083, 5.664]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.разметка001.geometry}
          material={nodes.разметка001.material}
          position={[41.8, 0.054, -24.146]}
          rotation={[0, -1.571, 0]}
          scale={[0.062, 0.005, 0.062]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.рамы001.geometry}
          material={materials.трубы}
          position={[41.8, 0.054, -24.146]}
          scale={[0.028, 0.053, 0.033]}
        />
        <group position={[42.751, 0.054, -24.145]} scale={[1, 1, 0.724]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube001.geometry}
            material={materials.трубы}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube001_1.geometry}
            material={materials.обводка}
          />
        </group>
        <group position={[41.783, 0.054, -24.146]} scale={[1, 1, 0.724]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube002.geometry}
            material={materials.трубы}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube002_1.geometry}
            material={materials.обводка}
          />
        </group>
        <group position={[41.759, 0.054, -24.146]} scale={[1, 1, 0.724]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube003.geometry}
            material={materials.трубы}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube003_1.geometry}
            material={materials.обводка}
          />
        </group>
        <group position={[41.788, 0.054, -24.146]} scale={[1, 1, 0.724]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube004.geometry}
            material={materials.трубы}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube004_1.geometry}
            material={materials.обводка}
          />
        </group>
        <group position={[41.872, 0.054, -24.146]} scale={[1, 1, 0.724]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube001.geometry}
            material={materials.трубы}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube001_1.geometry}
            material={materials.обводка}
          />
        </group>
        <group position={[40.597, 0.054, -24.147]} scale={[1, 1, 0.724]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube003.geometry}
            material={materials.трубы}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube003_1.geometry}
            material={materials.обводка}
          />
        </group>
        <group position={[41.783, 0.054, -24.667]} scale={[1, 1, 0.724]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube002.geometry}
            material={materials.трубы}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube002_1.geometry}
            material={materials.обводка}
          />
        </group>
        <group position={[41.78, 0.054, -20.59]} scale={[1, 1, 0.724]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube002.geometry}
            material={materials.трубы}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube002_1.geometry}
            material={materials.обводка}
          />
        </group>
        <group position={[41.78, 0.054, -20.179]} scale={[1, 1, 0.724]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube002.geometry}
            material={materials.трубы}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube002_1.geometry}
            material={materials.обводка}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.шахты001.geometry}
          material={materials.синька}
          position={[41.699, 0.054, -24.146]}
          scale={[0.36, 0.087, 0.36]}
        />
        <group position={[41.718, 0.054, -24.146]} scale={[0.36, 0.087, 0.36]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube007.geometry}
            material={materials.синька}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube007_1.geometry}
            material={materials.трубы}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.шахты004.geometry}
          material={materials.синька}
          position={[41.868, 0.054, -24.146]}
          scale={[0.36, 0.087, 0.36]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.шахты005.geometry}
          material={materials.синька}
          position={[41.606, 0.054, -24.146]}
          scale={[0.36, 0.087, 0.36]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.шахты006.geometry}
          material={materials.синька}
          position={[41.606, 1.193, -24.146]}
          scale={[0.36, 0.087, 0.36]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.шахты007.geometry}
          material={materials.синька}
          position={[41.211, 0.054, -24.146]}
          scale={[0.36, 0.087, 0.36]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.шахты008.geometry}
          material={materials.синька}
          position={[40.565, 0.054, -24.147]}
          scale={[0.36, 0.087, 0.36]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.шахты009.geometry}
          material={materials.синька}
          position={[39.907, 0.054, -24.147]}
          scale={[0.36, 0.087, 0.36]}
        />
        <group position={[40.25, 0.054, -24.147]} scale={[0.36, 0.087, 0.36]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube007.geometry}
            material={materials.синька}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube007_1.geometry}
            material={materials.трубы}
          />
        </group>
        <group position={[38.928, 0.054, -24.148]} scale={[0.36, 0.087, 0.36]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube007.geometry}
            material={materials.синька}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube007_1.geometry}
            material={materials.трубы}
          />
        </group>
        <group position={[37.13, 2.409, -27.609]} scale={[0.36, 0.087, 0.36]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube007.geometry}
            material={materials.синька}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube007_1.geometry}
            material={materials.трубы}
          />
        </group>
        <group position={[37.125, 2.409, -20.743]} scale={[0.36, 0.087, 0.36]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube007.geometry}
            material={materials.синька}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube007_1.geometry}
            material={materials.трубы}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.шахты014.geometry}
          material={materials.синька}
          position={[41.7, 0.054, -25.313]}
          scale={[0.36, 0.087, 0.36]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.шахты015.geometry}
          material={materials.синька}
          position={[41.7, 0.054, -26.48]}
          scale={[0.36, 0.087, 0.36]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.шахты016.geometry}
          material={materials.синька}
          position={[41.701, 0.054, -27.566]}
          scale={[0.36, 0.087, 0.36]}
        />
      </group>
    </a.group>
  );
}

export default Prom_n2;