import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from "@react-spring/three";
import * as THREE from "three";
import ModelScene from "../accests/3d/derevo_n2.glb";
import gsap from "gsap";

const Derevo_n2 = (props) => {
  const { nodes, materials } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false);

  const colorChange = async () => {
    let key = Object.keys(materials);
    if (props.fog)
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5,
          });
      });
    else {
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0,
          });
      });
    }
  };
  useEffect(() => {
    colorChange();
  }, [props.fog]);
  const modelRef = useRef();
  return (
    <a.group {...props} ref={modelRef}>
      <group name="Scene">
        <group name="Derevo">
          <group name="Derevo_v">
            <mesh
              name="Cone001"
              castShadow
              receiveShadow
              geometry={nodes.Cone001.geometry}
              material={materials.окружение}
              position={[-63.557, 2.044, -52.237]}
              scale={0.574}
            />
            <mesh
              name="Cone002"
              castShadow
              receiveShadow
              geometry={nodes.Cone002.geometry}
              material={materials.окружение}
              position={[-8.333, 2.044, -36.37]}
              scale={0.574}
            />
            <mesh
              name="Cone003"
              castShadow
              receiveShadow
              geometry={nodes.Cone003.geometry}
              material={materials.окружение}
              position={[-4.999, 2.044, -28.591]}
              scale={0.574}
            />
            <mesh
              name="Cone004"
              castShadow
              receiveShadow
              geometry={nodes.Cone004.geometry}
              material={materials.окружение}
              position={[-56.384, 2.044, -58.195]}
              scale={0.574}
            />
            <mesh
              name="Cone006"
              castShadow
              receiveShadow
              geometry={nodes.Cone006.geometry}
              material={materials.окружение}
              position={[-9.681, 2.044, 4.078]}
              scale={0.574}
            />
            <mesh
              name="Cone007"
              castShadow
              receiveShadow
              geometry={nodes.Cone007.geometry}
              material={materials.окружение}
              position={[36.056, 2.044, 2.445]}
              scale={0.574}
            />
            <mesh
              name="Cone008"
              castShadow
              receiveShadow
              geometry={nodes.Cone008.geometry}
              material={materials.окружение}
              position={[48.889, 2.044, -57.049]}
              scale={0.574}
            />
            <mesh
              name="Cone009"
              castShadow
              receiveShadow
              geometry={nodes.Cone009.geometry}
              material={materials.окружение}
              position={[42.531, 2.044, 4.064]}
              scale={0.574}
            />
            <mesh
              name="Cone010"
              castShadow
              receiveShadow
              geometry={nodes.Cone010.geometry}
              material={materials.окружение}
              position={[33.866, 2.044, 13.586]}
              scale={0.574}
            />
            <mesh
              name="Cone011"
              castShadow
              receiveShadow
              geometry={nodes.Cone011.geometry}
              material={materials.окружение}
              position={[0.347, 2.044, 11.396]}
              scale={0.574}
            />
            <mesh
              name="Cone012"
              castShadow
              receiveShadow
              geometry={nodes.Cone012.geometry}
              material={materials.окружение}
              position={[43.483, 2.044, 13.586]}
              scale={0.574}
            />
            <mesh
              name="Cone013"
              castShadow
              receiveShadow
              geometry={nodes.Cone013.geometry}
              material={materials.окружение}
              position={[-2.796, 2.044, 12.253]}
              scale={0.574}
            />
            <mesh
              name="Cone014"
              castShadow
              receiveShadow
              geometry={nodes.Cone014.geometry}
              material={materials.окружение}
              position={[-53.405, 2.044, -72.374]}
              scale={0.574}
            />
            <mesh
              name="Cone018"
              castShadow
              receiveShadow
              geometry={nodes.Cone018.geometry}
              material={materials.окружение}
              position={[27.451, 2.044, -51.041]}
              scale={0.574}
            />
            <mesh
              name="Cone019"
              castShadow
              receiveShadow
              geometry={nodes.Cone019.geometry}
              material={materials.окружение}
              position={[12.935, 2.044, -57.377]}
              scale={0.574}
            />
            <mesh
              name="Cone020"
              castShadow
              receiveShadow
              geometry={nodes.Cone020.geometry}
              material={materials.окружение}
              position={[-27.31, 2.044, -54.113]}
              scale={0.574}
            />
            <mesh
              name="Cone021"
              castShadow
              receiveShadow
              geometry={nodes.Cone021.geometry}
              material={materials.окружение}
              position={[-26.808, 2.044, -33.731]}
              scale={0.574}
            />
            <mesh
              name="Cylinder001"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder001.geometry}
              material={materials.окружение}
              position={[-36.809, 1.505, -85.059]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder002"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder002.geometry}
              material={materials.окружение}
              position={[33.236, 1.505, -56.346]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder003"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder003.geometry}
              material={materials.окружение}
              position={[-27.655, 1.505, -44.705]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder004"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder004.geometry}
              material={materials.окружение}
              position={[-68.102, 1.505, -15.493]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder005"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder005.geometry}
              material={materials.окружение}
              position={[-69.574, 1.505, -25.791]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder006"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder006.geometry}
              material={materials.окружение}
              position={[-3.622, 1.505, -52.266]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder007"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder007.geometry}
              material={materials.окружение}
              position={[-13.326, 1.505, -28.699]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder008"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder008.geometry}
              material={materials.окружение}
              position={[50.7, 1.505, -23.302]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder009"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder009.geometry}
              material={materials.окружение}
              position={[-8.87, 1.505, -30.134]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder010"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder010.geometry}
              material={materials.окружение}
              position={[33.952, 1.505, -34.732]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder012"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder012.geometry}
              material={materials.окружение}
              position={[-54.902, 1.505, -67.82]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder013"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder013.geometry}
              material={materials.окружение}
              position={[-30.758, 1.505, 7.593]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder014"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder014.geometry}
              material={materials.окружение}
              position={[28.032, 1.505, 14.184]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder015"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder015.geometry}
              material={materials.окружение}
              position={[3.249, 1.505, -50.119]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder016"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder016.geometry}
              material={materials.окружение}
              position={[-14.794, 1.505, -80.078]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder017"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder017.geometry}
              material={materials.окружение}
              position={[45.433, 1.505, 20.764]}
              scale={[0.202, 0.995, 0.202]}
            />
            <mesh
              name="Cylinder018"
              castShadow
              receiveShadow
              geometry={nodes.Cylinder018.geometry}
              material={materials.окружение}
              position={[-32.637, 1.505, -41.491]}
              scale={[0.202, 0.995, 0.202]}
            />
          </group>
          <group
            name="деревья001"
            position={[8.681, 0, -116.968]}
            rotation={[0, -0.054, 0]}
            scale={0.23}
          >
            <mesh
              name="Circle051"
              castShadow
              receiveShadow
              geometry={nodes.Circle051.geometry}
              material={materials.листва}
            />
            <mesh
              name="Circle051_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle051_1.geometry}
              material={materials.ствол}
            />
          </group>
          <group
            name="деревья003"
            position={[-107.537, 0, -11.912]}
            rotation={[-Math.PI, 0.994, -Math.PI]}
            scale={0.23}
          >
            <mesh
              name="Circle051"
              castShadow
              receiveShadow
              geometry={nodes.Circle051.geometry}
              material={materials.листва}
            />
            <mesh
              name="Circle051_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle051_1.geometry}
              material={materials.ствол}
            />
          </group>
          <group
            name="деревья004"
            position={[-130.551, 0, -33.718]}
            rotation={[0, 1.323, 0]}
            scale={0.23}
          >
            <mesh
              name="Circle051"
              castShadow
              receiveShadow
              geometry={nodes.Circle051.geometry}
              material={materials.листва}
            />
            <mesh
              name="Circle051_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle051_1.geometry}
              material={materials.ствол}
            />
          </group>
          <group
            name="деревья005"
            position={[-64.911, 0, 35.887]}
            rotation={[-Math.PI, 1.201, -Math.PI]}
            scale={0.23}
          >
            <mesh
              name="Circle051"
              castShadow
              receiveShadow
              geometry={nodes.Circle051.geometry}
              material={materials.листва}
            />
            <mesh
              name="Circle051_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle051_1.geometry}
              material={materials.ствол}
            />
          </group>
          <group
            name="деревья006"
            position={[-25.73, 0, 40.802]}
            rotation={[-Math.PI, 0.994, -Math.PI]}
            scale={0.23}
          >
            <mesh
              name="Circle051"
              castShadow
              receiveShadow
              geometry={nodes.Circle051.geometry}
              material={materials.листва}
            />
            <mesh
              name="Circle051_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle051_1.geometry}
              material={materials.ствол}
            />
          </group>
          <group
            name="деревья007"
            position={[-38.633, 0, -135.748]}
            rotation={[0, 0.427, 0]}
            scale={0.23}
          >
            <mesh
              name="Circle051"
              castShadow
              receiveShadow
              geometry={nodes.Circle051.geometry}
              material={materials.листва}
            />
            <mesh
              name="Circle051_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle051_1.geometry}
              material={materials.ствол}
            />
          </group>
          <group
            name="деревья008"
            position={[-127.382, 0, -47.824]}
            rotation={[-Math.PI, 1.492, -Math.PI]}
            scale={0.23}
          >
            <mesh
              name="Circle051"
              castShadow
              receiveShadow
              geometry={nodes.Circle051.geometry}
              material={materials.листва}
            />
            <mesh
              name="Circle051_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle051_1.geometry}
              material={materials.ствол}
            />
          </group>
          <group name="деревья009" rotation={[0, -1.099, 0]} scale={0.23}>
            <mesh
              name="Circle051"
              castShadow
              receiveShadow
              geometry={nodes.Circle051.geometry}
              material={materials.листва}
            />
            <mesh
              name="Circle051_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle051_1.geometry}
              material={materials.ствол}
            />
          </group>
          <group
            name="деревья010"
            position={[-3.052, 0, -108.114]}
            rotation={[0, -1.099, 0]}
            scale={0.23}
          >
            <mesh
              name="Circle051"
              castShadow
              receiveShadow
              geometry={nodes.Circle051.geometry}
              material={materials.листва}
            />
            <mesh
              name="Circle051_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle051_1.geometry}
              material={materials.ствол}
            />
          </group>
          <group name="деревья011" rotation={[0, 0.288, 0]} scale={0.23}>
            <mesh
              name="Circle062"
              castShadow
              receiveShadow
              geometry={nodes.Circle062.geometry}
              material={materials.листва}
            />
            <mesh
              name="Circle062_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle062_1.geometry}
              material={materials.ствол}
            />
          </group>
          <group
            name="деревья012"
            position={[-0.168, 0, -49.248]}
            rotation={[-Math.PI, 1.492, -Math.PI]}
            scale={0.23}
          >
            <mesh
              name="Circle051"
              castShadow
              receiveShadow
              geometry={nodes.Circle051.geometry}
              material={materials.листва}
            />
            <mesh
              name="Circle051_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle051_1.geometry}
              material={materials.ствол}
            />
          </group>
          <group
            name="деревья013"
            position={[97.697, 0, 55.818]}
            rotation={[Math.PI, -0.978, Math.PI]}
            scale={0.23}
          >
            <mesh
              name="Circle051"
              castShadow
              receiveShadow
              geometry={nodes.Circle051.geometry}
              material={materials.листва}
            />
            <mesh
              name="Circle051_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle051_1.geometry}
              material={materials.ствол}
            />
          </group>
        </group>
      </group>
    </a.group>
  );
};

export default Derevo_n2;
