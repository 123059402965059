import {createContext} from 'react'
import {useNavigate} from 'react-router-dom';


function noop() {
  //console.log("asdasd")
}

export const AuthContext = createContext({
  token: null,
  email: null,
  login: noop,
  logout: noop,
  isAuthenticated: false
})