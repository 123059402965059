import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from "@react-spring/three";
import * as THREE from "three";
import ModelScene from "../accests/3d/klumbi_n.glb";
import gsap from "gsap";

const Klumbi_n = (props) => {
  const { nodes, materials } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false);

  const colorChange = async () => {
    let key = Object.keys(materials);
    if (props.fog && !hovered)
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5,
          });
      });
    else {
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0,
          });
      });
    }
  };
  useEffect(() => {
    colorChange();
  }, [props.fog]);
  const modelRef = useRef();
  return (
    <a.group
      {...props}
      ref={modelRef}
      
    >
      <group rotation={[0, -Math.PI / 2, 0]} scale={[1.553, 1.583, 1.553]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube061.geometry}
          material={materials.карусель}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube061_1.geometry}
          material={materials.перилла}
        />
      </group>
      <group
        position={[0, -0.449, 0]}
        rotation={[-1.067, 0.705, 0.865]}
        scale={0.043}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001.geometry}
          material={materials.клумба}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_1.geometry}
          material={materials.ЗЕМЛЯ}
        />
      </group>
      <group
        position={[11.801, -0.286, -3.847]}
        rotation={[-1.067, 0.705, 0.865]}
        scale={0.043}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001.geometry}
          material={materials.клумба}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_1.geometry}
          material={materials.ЗЕМЛЯ}
        />
      </group>
      <group
        position={[-12.047, -0.416, -3.847]}
        rotation={[-1.067, 0.705, 0.865]}
        scale={0.043}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001.geometry}
          material={materials.клумба}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_1.geometry}
          material={materials.ЗЕМЛЯ}
        />
      </group>
      <group
        position={[99.898, -0.449, 64.483]}
        rotation={[-1.067, 0.705, 0.865]}
        scale={0.043}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001.geometry}
          material={materials.клумба}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_1.geometry}
          material={materials.ЗЕМЛЯ}
        />
      </group>
    </a.group>
  );
};

export default Klumbi_n;
