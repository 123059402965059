import { useEffect, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Plane, useAnimations, useGLTF } from "@react-three/drei";
import planeScene from "../accests/3d/plane_n.glb";

function Plane_n(props) {
  const planeRef = useRef();
  const { scene, nodes, materials } = useGLTF(planeScene);
  return (
    <group {...props} ref={planeRef}>
      {/* <ambientLight intensity={2} /> */}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane.geometry}
        material={materials.Plane}
        scale={467.311}
      />
    </group>
  );
}

export default Plane_n;
