import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext'
import { useHttp } from '../hooks/http.hook'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import Chart from '../components/ChartComponents';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import TableComponent from '../components/TableComponents';
import MenuCreateComponents from '../components/MenuCreateComponents';
import MainListItemsComponents from '../components/MainListItemsComponents';
import PostCreateComponents from '../components/PostCreateComponents';
import PostTableComponent from '../components/EquipmentsTableComponent';
import PostTableComponentPost from '../components/PostTableComponentsPost';
import DraftEditor from "../components/Editor/DraftEditor";
import ReshenieCreateComponents from '../components/ReshenieCreateComponents';
import MakingCreateComponents from '../components/EquipmentsCreateComponents';
import AboutCreateComponents from '../components/CompanyCreateComponents';
import HelpingCreateComponents from '../components/HelpingCreateComponents';
import PostTableComponentsCategoryEquipment from '../components/EquipmentsGroupTableComponents';
import PostCreateComponentsCategoryEquipment from '../components/EquipmentsGroupCreateComponents';
import EquipmentsTableComponent from '../components/EquipmentsTableComponent';
import EquipmentsCreateComponents from '../components/EquipmentsCreateComponents';
import EquipmentsGroupTableComponents from '../components/EquipmentsGroupTableComponents';
import EquipmentsGroupCreateComponents from '../components/EquipmentsGroupCreateComponents';
import EquipmentsPostsTableComponents from '../components/EquipmentPostsTableComponents';
import EquipmentsPostsCreateComponents from '../components/EquipmentsPostsCreateComponents';
import DocumentsTableComponents from '../components/DocumentsTableComponents';
import DocumentsCreateComponents from '../components/DocumentsCreateComponents';
import DocumentsPostTableComponents from '../components/DocumentsPostTableComponents';
import DocumentsPostCreateComponents from '../components/DocumentsPostCreateComponents';
import SolutionsTableComponent from '../components/SolutionsTableComponent';
import SolutionsCreateComponents from '../components/SolutionsCreateComponents';
import CompanyTableComponent from '../components/CompanyTableComponent';
import CompanyCreateComponents from '../components/CompanyCreateComponents';
import SupportsTableComponent from '../components/SupportsTableComponent';
import SupportCreateComponents from '../components/SupportCreateComponents';
import ReferenceGroupTableComponent from '../components/ReferenceGroupTableComponent';
import ReferenceGroupCreateComponents from '../components/ReferenceGroupCreateComponents';
import ReferencePostTableComponents from '../components/ReferencePostTableComponents';
import ReferencePostCreateComponents from '../components/ReferencePostCreateComponents';
import DocumentsSupportsPostTableComponents from '../components/DocumentsSupportsPostTableComponents';
import DocumentsSupportsPostCreateComponents from '../components/DocumentsSupportsPostCreateComponents';
import DocumentsSolutionsPostTableComponents from '../components/DocumentsSolutionsPostTableComponents';
import DocumentsSolutionsPostCreateComponents from '../components/DocumentsSolutionsPostCreateComponents';
import DocumentsCompanyPostTableComponents from '../components/DocumentsCompanyPostTableComponents';
import DocumentsCompanyPostCreateComponents from '../components/DocumentsCompanyPostCreateComponents';
// import Deposits from './Deposits';
// import Orders from './Orders';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export const AdminPage = () => {
  const { token, logout } = useContext(AuthContext)
  const [selectRowPost, setSelectRowPost] = useState(null)
  const [rowCategory, setRowCategory] = useState(null)
  const [selectRow, setSelectRow] = useState(null)
  const [selectPostRow, setSelectPostRow] = useState(null)
  const [updateTable, setUpdateTable] = useState(null)
  const { loading, request, error, clearError } = useHttp()
  const [menuSelected, setMenuSelected] = useState(1)
  const [PostRows, setPostRows] = useState(null)
  const [categoryHeaders, setCategoryHeaders] = useState(null)
  const [open, setOpen] = React.useState(true);

  const [making, setMaking] = useState(null) // Оборудованиие
  const [makingSelectRow, setMakingSelectRow] = useState(null) // Оборудование


  const [equipmentsGroupRows, setEquipmentsGroupRows] = useState(null) // Группы оборудования
  const [selectEquipmentsGroupRows, setSelectEquipmentsGroupRows] = useState(null) // Группы оборудования

  const [equipmentsPostRows, setEquipmentsPostRows] = useState(null) // Записи оборудования
  const [selectEquipmentsPostRows, setSelectEquipmentsPostRows] = useState(null) // Записи оборудования

  const [documentsRows, setDocumentsRows] = useState(null) // Документы оборудования
  const [selectDocumentsRows, setSelectDocumentsRows] = useState(null) // Документы оборудования

  const [documentsPostRows, setDocumentsPostRows] = useState(null) // Документы<->Записи оборудования
  const [selectDocumentsPostRows, setSelectDocumentsPostRows] = useState(null) // Документы<->Записи оборудования

  const [solutionsRows, setSolutionsRows] = useState(null) // Решения
  const [selectSolutionsRows, setSelectSolutionsRows] = useState(null) // Решения
  const [documentsSolutionsPostRows, setDocumentsSolutionsPostRows] = useState(null) // Документы<->Решения
  const [selectDocumentsSolutionsPostRows, setSelectDocumentsSolutionsPostRows] = useState(null) // Документы<->Решения

  const [companyRows, setCompanyRows] = useState(null) // Компания
  const [selectCompanyRow, setSelectCompanyRow] = useState(null) // Компания
  const [documentsCompanyPostRows, setDocumentsCompanyPostRows] = useState(null) // Документы<->Компания
  const [selectDocumentsCompanyPostRows, setSelectDocumentsCompanyPostRows] = useState(null) // Документы<->Компания

  const [supportRows, setSupportRows] = useState(null) // Техническая поддержка
  const [selectSupportRow, setSelectSupportRow] = useState(null) // Техническая поддержка
  const [documentsSupportsPostRows, setDocumentsSupportsPostRows] = useState(null) // Документы<->ТП
  const [selectDocumentsSupportsPostRows, setSelectDocumentsSupportsPostRows] = useState(null) // Документы<->ТП

  const [referencesGroupRows, setReferencesGroupRows] = useState(null) // Группа референций
  const [selectReferencesGroupRow, setSelectReferencesGroupRow] = useState(null) // Группа референций

  const [referencesPostRows, setReferencesPostRows] = useState(null) // Запись референций
  const [selectReferencesPostRow, setSelectReferencesPostRow] = useState(null) // Запись референций

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const getCategory = async () => {
    const datat = await request('/api/menu', 'GET', null, {
      authorization: `${token}`
    }
    )
    if (datat) {
      let bufRows = []
      let bufHeaders = []
      //console.log(datat)
      for (let row of datat) {
        bufRows.push(row)
      }
      // setCategoryRows(bufRows)
    }
  }


  // Запрос на получение всего списка оборудования
  const getMaking = async () => {
    const data = await request('/api/equipments/', 'GET', null, {
      authorization: `${token}`
    })
    setMaking(data)
  }

  // Запрос на получение всех групп с оборудованием
  const getEquipmentsPosts = async () => {
    const data = await request('/api/equipments_posts/', 'GET', null, {
      authorization: `${token}`
    })
    setEquipmentsPostRows(data)
  }

  // Запрос на получение всех записей по оборудованию
  const getEquipmentGroup = async () => {
    const data = await request('/api/equipments_groups/', 'GET', null, {
      authorization: `${token}`
    })
    setEquipmentsGroupRows(data)
  }

  // Запрос на получение всех документов по оборудованию
  const getDocuments = async () => {
    const data = await request('/api/documents/', 'GET', null, {
      authorization: `${token}`
    })
    setDocumentsRows(data)
  }

  // Запрос на получение всех документов по оборудованию
  const getDocumentsPost = async () => {
    const data = await request('/api/equipments_posts_documents/', 'GET', null, {
      authorization: `${token}`
    })
    setDocumentsPostRows(data)
  }

  // Запрос на получение всех документов по ТП
  const getDocumentsSupportsPost = async () => {
    const data = await request('/api/supports_posts_documents/', 'GET', null, {
      authorization: `${token}`
    })
    setDocumentsSupportsPostRows(data)
  }

    // Запрос на получение всех документов по Компании
    const getDocumentsCompanyPost = async () => {
      const data = await request('/api/companys_posts_documents/', 'GET', null, {
        authorization: `${token}`
      })
      setDocumentsCompanyPostRows(data)
    }

  // Запрос на получение всех документов по Решениям
  const getDocumentsSolutionsPost = async () => {
    const data = await request('/api/solutions_posts_documents/', 'GET', null, {
      authorization: `${token}`
    })
    setDocumentsSolutionsPostRows(data)
  }


  // Запрос на получение всех решений
  const getSolutions = async () => {
    const data = await request('/api/solutions_posts/', 'GET', null, {
      authorization: `${token}`
    })
    setSolutionsRows(data)
  }

  // Запрос на получение всех записей о компании
  const getCompany = async () => {
    const data = await request('/api/companys_posts/', 'GET', null, {
      authorization: `${token}`
    })
    setCompanyRows(data)
  }

  // Запрос на получение всех записей о технической поддержки
  const getSupport = async () => {
    const data = await request('/api/supports_posts/', 'GET', null, {
      authorization: `${token}`
    })
    setSupportRows(data)
  }

  // Запрос на получение всех групп референций
  const getReferenceGroup = async () => {
    const data = await request('/api/references_groups/', 'GET', null, {
      authorization: `${token}`
    })
    setReferencesGroupRows(data)
  }

  // Запрос на получение всех групп референций
  const getReferencePost = async () => {
    const data = await request('/api/references_posts/', 'GET', null, {
      authorization: `${token}`
    })
    setReferencesPostRows(data)
  }

  const getPost = async () => {
    const datat = await request('/api/posts', 'GET', null, {
      authorization: `${token}`
    }
    )
    if (datat) {
      let bufRows = []
      let bufHeaders = []
      for (let row of datat) {
        bufRows.push(row)
      }
      //console.log(bufRows)
      setPostRows(bufRows)
    }
  }

  // const getHelping = async () => {
  //   const data = await request('/api/helping', 'GET', null, {
  //     authorization: `${token}`
  //   })
  //   setHelping(data)
  // }

  React.useEffect(() => {
    // const getCurrentUser = async () => {
    //   const datat = await request('/api/auth/current', 'GET', null, {
    //     authorization: `${token}`
    //   }
    //   )
    //   // //console.log(datat, token)
    // }

    // getCurrentUser()
    // // getCategory()
    // getPost()
  }, [])




  useEffect(() => {
    //console.log("updateTable", updateTable)
    if (updateTable) {
      // getCategory()
      setSelectRow(null)
      setMakingSelectRow(null)
      setUpdateTable(0)
    }
  }, updateTable)

  useEffect(() => {
    setSelectDocumentsPostRows(null)
    setSelectDocumentsRows(null)
    setSelectDocumentsSolutionsPostRows(null)
    setSelectDocumentsSupportsPostRows(null)
    setSelectDocumentsCompanyPostRows(null)
    setSelectEquipmentsPostRows(null)
    setSelectEquipmentsGroupRows(null)
    setMakingSelectRow(null)
    setSelectCompanyRow(null)
    setSelectSolutionsRows(null)
    setSelectSupportRow(null)
    setSelectReferencesGroupRow(null)
    setSelectReferencesPostRow(null)


    if (menuSelected == 1) {
      getMaking()
    } else if (menuSelected == 2) {
      // getCategory()
      getSolutions()
    } else if (menuSelected == 3) {
      getCompany()
    } else if (menuSelected == 4) {
      getSupport()
    } else if (menuSelected == 6) {
      getEquipmentGroup()
    } else if (menuSelected == 7) {
      getEquipmentsPosts()
    } else if (menuSelected == 8) {
      getDocuments()
    } else if (menuSelected == 9) {
      getDocumentsPost()
    } else if (menuSelected == 5) {
      getReferenceGroup()
    } else if (menuSelected == 10) {
      getReferencePost()
    } else if (menuSelected == 11) {
      getDocumentsSolutionsPost()
    } else if(menuSelected == 12){
      getDocumentsCompanyPost()
    }else if(menuSelected == 13){
      getDocumentsSupportsPost()
    }
  }, [menuSelected])

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Панель Администратора
            </Typography>
            <IconButton color="inherit" onClick={() => { logout() }}>
              <Badge >
                <ExitToAppIcon style={{ color: 'black' }} />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainListItemsComponents selected={setMenuSelected}></MainListItemsComponents>
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

            {/* Оборудование */}
            {menuSelected == 1 &&
              <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <EquipmentsTableComponent headers={['Заголовок', 'Текст', 'Подробнее', 'Картинка']} selected={setMakingSelectRow} data={making} />

                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <EquipmentsCreateComponents select={makingSelectRow} update={() => {
                      getMaking()
                      setMakingSelectRow(null)
                    }}
                    ></EquipmentsCreateComponents>
                  </Paper>
                </Grid>
              </Grid>
            }

            {/* Группы оборудования */}
            {menuSelected == 6 &&
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <EquipmentsGroupTableComponents headers={['Название', 'Компания']} selected={setSelectEquipmentsGroupRows} data={equipmentsGroupRows} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <EquipmentsGroupCreateComponents select={selectEquipmentsGroupRows} update={() => {
                      setSelectEquipmentsGroupRows(null)
                      getEquipmentGroup()
                    }}></EquipmentsGroupCreateComponents>

                  </Paper>
                </Grid>
              </Grid>
            }

            {/* Записи оборудования */}
            {menuSelected == 7 &&
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <EquipmentsPostsTableComponents headers={['Название', 'Текст', 'Описание', 'Предварительная картинка', 'Картинка', 'Группа']} selected={setSelectEquipmentsPostRows} data={equipmentsPostRows} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <EquipmentsPostsCreateComponents select={selectEquipmentsPostRows} update={() => {
                      setSelectEquipmentsPostRows(null)
                      getEquipmentsPosts()
                    }}></EquipmentsPostsCreateComponents>

                  </Paper>
                </Grid>
              </Grid>
            }

            {/* Документы оборудования */}
            {menuSelected == 8 &&
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <DocumentsTableComponents headers={['Название', 'Ссылка']} selected={setSelectDocumentsRows} data={documentsRows} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <DocumentsCreateComponents select={selectDocumentsRows} update={() => {
                      setSelectDocumentsRows(null)
                      getDocuments()
                    }}></DocumentsCreateComponents>

                  </Paper>
                </Grid>
              </Grid>
            }

            {/* Запись <-> Документы */}
            {menuSelected == 9 &&
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <DocumentsPostTableComponents headers={['Запись', 'Документ']} selected={setSelectDocumentsPostRows} data={documentsPostRows} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <DocumentsPostCreateComponents select={selectDocumentsPostRows} update={() => {
                      setSelectDocumentsPostRows(null)
                      getDocumentsPost()
                    }}></DocumentsPostCreateComponents>

                  </Paper>
                </Grid>
              </Grid>
            }

            {/* Решения */}
            {menuSelected == 2 &&
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <SolutionsTableComponent headers={['Заголовок', 'Текст', 'Подробнее', 'Картинка']} selected={setSelectSolutionsRows} data={solutionsRows} />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <SolutionsCreateComponents select={selectSolutionsRows} update={() => {
                      setSelectSolutionsRows(null)
                      getSolutions()
                    }}></SolutionsCreateComponents>
                  </Paper>
                </Grid>
              </Grid>
            }

            {/* Решения <-> Документы */}
            {menuSelected == 11 &&
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <DocumentsSolutionsPostTableComponents headers={['Запись', 'Документ']} selected={setSelectDocumentsSolutionsPostRows} data={documentsSolutionsPostRows} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <DocumentsSolutionsPostCreateComponents select={selectDocumentsSolutionsPostRows} update={() => {
                      setSelectDocumentsSolutionsPostRows(null)
                      getDocumentsSolutionsPost()
                    }}></DocumentsSolutionsPostCreateComponents>

                  </Paper>
                </Grid>
              </Grid>
            }

            {/* О компании */}
            {menuSelected == 3 &&
              <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <CompanyTableComponent headers={['Заголовок', 'Текст', 'Подробнее', 'Картинка']} selected={setSelectCompanyRow} data={companyRows} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <CompanyCreateComponents select={selectCompanyRow} update={() => {
                      getCompany()
                      setSelectCompanyRow(null)
                    }}
                    ></CompanyCreateComponents>
                  </Paper>
                </Grid>
              </Grid>
            }

            {/* О компании <-> Документы */}
            {menuSelected == 12 &&
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <DocumentsCompanyPostTableComponents headers={['Запись', 'Документ']} selected={setSelectDocumentsCompanyPostRows} data={documentsCompanyPostRows} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <DocumentsCompanyPostCreateComponents select={selectDocumentsCompanyPostRows} update={() => {
                      setSelectDocumentsCompanyPostRows(null)
                      getDocumentsCompanyPost()
                    }}></DocumentsCompanyPostCreateComponents>

                  </Paper>
                </Grid>
              </Grid>
            }


            {/* Техническая поддержка */}
            {menuSelected == 4 &&
              <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <SupportsTableComponent headers={['Заголовок', 'Текст', 'Подробнее', 'Картинка']} selected={setSelectSupportRow} data={supportRows} />
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <SupportCreateComponents select={selectSupportRow} update={() => {
                      getSupport()
                      setSelectSupportRow(null)
                    }}
                    ></SupportCreateComponents>
                  </Paper>
                </Grid>
              </Grid>
            }

            {/* ТП <-> Документы */}
            {menuSelected == 13 &&
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <DocumentsSupportsPostTableComponents headers={['Запись', 'Документ']} selected={setSelectDocumentsSupportsPostRows} data={documentsSupportsPostRows} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <DocumentsSupportsPostCreateComponents select={selectDocumentsSupportsPostRows} update={() => {
                      setSelectDocumentsSupportsPostRows(null)
                      getDocumentsSupportsPost()
                    }}></DocumentsSupportsPostCreateComponents>

                  </Paper>
                </Grid>
              </Grid>
            }

            {/* Группа референций */}
            {menuSelected == 5 &&
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <ReferenceGroupTableComponent headers={['Заголовок']} selected={setSelectReferencesGroupRow} data={referencesGroupRows} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <ReferenceGroupCreateComponents select={selectReferencesGroupRow} update={() => {
                      getReferenceGroup()
                      setSelectReferencesGroupRow(null)
                    }}></ReferenceGroupCreateComponents>

                  </Paper>
                </Grid>
              </Grid>
            }

            {/* Записи референций */}
            {menuSelected == 10 &&
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <ReferencePostTableComponents headers={['Название', 'Текст', 'Картинка', 'Группа']} selected={setSelectReferencesPostRow} data={referencesPostRows} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <ReferencePostCreateComponents select={selectReferencesPostRow} update={() => {
                      setSelectReferencesPostRow(null)
                      getReferencePost()
                    }}></ReferencePostCreateComponents>

                  </Paper>
                </Grid>
              </Grid>
            }
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}