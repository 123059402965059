import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from "@react-spring/three";
import * as THREE from "three";
import ModelScene from "../accests/3d/dorogki_n.glb";
import gsap from "gsap";

const Dorogki_n = (props) => {
  const { nodes, materials } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false);

  const colorChange = async () => {
    let key = Object.keys(materials);
    if (props.fog && !hovered)
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5,
          });
      });
    else {
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0,
          });
      });
    }
  };
  useEffect(() => {
    colorChange();
  }, [props.fog]);
  const modelRef = useRef();
  return (
    <a.group
      {...props}
      ref={modelRef}
       
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        material={materials.дорожка}
        rotation={[Math.PI, -0.021, Math.PI]}
        scale={[27.054, 0.039, 13.882]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002.geometry}
        material={materials.дорожка}
        rotation={[0, 1.571, 0]}
        scale={[32.953, 0.039, 13.882]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube008.geometry}
        material={materials.дорожка}
        position={[0, -0.384, 0]}
        scale={[42.544, 0.039, 13.882]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube037.geometry}
        material={materials.дорожка}
        rotation={[0, -1.571, 0]}
        scale={[27.054, 0.039, 13.882]}
      />
    </a.group>
  );
};

export default Dorogki_n;
