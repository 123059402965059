import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from '@react-spring/three'
import * as THREE from 'three'
import ModelScene from '../accests/3d/hospital_n.glb'
import gsap from "gsap";

const Hospital_n = (props) => {
  const { nodes, materials } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false)

  const colorChange = async () => {
    let key = Object.keys(materials)
    if (props.fog && !hovered)
      key.forEach(element => {
        if (element != 'окна' && element != 'выступы')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5
          });
      });
    else {
      key.forEach(element => {
        if (element != 'окна' && element != 'выступы')
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0
          });
      });
    }
  }
  useEffect(() => {
    colorChange()
  }, [props.fog])
  const modelRef = useRef()
  return (
    <a.group {...props} ref={modelRef}
      onPointerOver={(event) => {
        event.stopPropagation()
        hover(true)
        props.setFog(true)
      }
      }
      onPointerOut={(event) => {
        hover(false)
        props.setFog(false)
      }}
      onClick={()=>{
        props.select(7)
      }}
    >
      <group position={[39.968, 0.409, 50.455]} scale={1.087}>
        <group
          position={[-36.758, -0.376, -46.403]}
          scale={[2.784, 4.478, 2.784]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube049.geometry}
            material={materials["здание.006"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube049_1.geometry}
            material={materials.крест}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube049_2.geometry}
            material={materials.арка}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube049_3.geometry}
            material={materials.шахта}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube023.geometry}
          material={materials.шахта}
          position={[-36.758, -0.376, -46.403]}
          scale={[1.295, 4.039, 1.494]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube028.geometry}
          material={materials.шахта}
          position={[-36.758, -0.376, -46.403]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[0.73, 11.475, 1.397]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube035.geometry}
          material={materials.шахта}
          position={[-36.758, -0.376, -46.403]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={[0.73, 11.475, 1.397]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube036.geometry}
          material={materials.шахта}
          position={[-36.758, -0.376, -46.403]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[0.73, 11.963, 1.397]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube041.geometry}
          material={materials.шахта}
          position={[-36.758, -0.376, -46.403]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={[0.73, 12.898, 1.397]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube042.geometry}
          material={materials.шахта}
          position={[-36.758, -0.376, -46.403]}
          rotation={[0, 0, -Math.PI]}
          scale={[0.73, 4.747, 1.397]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube043.geometry}
          material={materials.шахта}
          position={[-36.758, -0.376, -46.403]}
          rotation={[Math.PI, -1.571, 0]}
          scale={[0.73, 4.747, 1.397]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.окна001.geometry}
          material={materials["окна.004"]}
          position={[-36.758, -0.376, -46.403]}
          scale={[2.784, 4.478, 2.784]}
        />
      </group>
    </a.group>
  );
}

export default Hospital_n;