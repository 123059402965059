import React, { useRef, useEffect, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { a } from "@react-spring/three";
import * as THREE from "three";
import ModelScene from "../accests/3d/kran3.glb";
import gsap from "gsap";

const Cran = (props) => {
  const { nodes, materials, animations } = useGLTF(ModelScene);
  const [hovered, hover] = useState(false);
  const modelRef = useRef();
  const { actions } = useAnimations(animations, modelRef);

  useEffect(() => {
    console.log("car", actions);
    actions["Kran1Action.001"].play();
    // actions["Cube.006Action"].play();
    // actions['Cube.041Action.001'].play();
    // actions["].play();
  }, []);
  const colorChange = async () => {
    let key = Object.keys(materials);
    // //console.log(key)
    if (props.fog && !hovered)
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0.5,
            g: 0.5,
            b: 0.5,
          });
      });
    else {
      key.forEach((element) => {
        if (element != "окна" && element != "выступы")
          gsap.to(materials[element].emissive, 0.3, {
            r: 0,
            g: 0,
            b: 0,
          });
      });
    }
  };
  useEffect(() => {
    colorChange();
  }, [props.fog]);
  return (
    <a.group {...props} ref={modelRef}>
      <group name="Scene">
        <mesh
          name="Kran1"
          castShadow
          receiveShadow
          geometry={nodes.Kran1.geometry}
          material={materials["phong35.002"]}
          position={[-68.056, 20.141, -23.639]}
          rotation={[0, 0.119, 0]}
          scale={[255.686, 383.369, 255.686]}
        />
      </group>
    </a.group>
  );
};

export default Cran;
